import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../../components/Loading/Loading";
import Pagination from "../../../components/Pagination/Pagination";
import KeywordsTable from "./Charts/KeywordsTable";
import SearchVolMonth from "./Charts/SearchVolMonth";

import { $axios } from "../../../services/axiosInstance";
import useKeywordOptions from "./hooks/useKeywordOptions";

import spinner from '../../../assets/img/spinner.svg'

import '../Admin.css';
import './KeywordUniverse.css';

function KeywordUniverse() {
  const {
    projects, 
    conditions,
    branded_categories, 
    unbranded_categories, 
    loading, 
    error
  } = useKeywordOptions();
  const [type, setType] = useState(sessionStorage.getItem('type') || '');
  const [searching, setSearching] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  // search form inputs
  const [projectInput, setProjectInput] = useState(sessionStorage.getItem('project') || '');
  const [conditionInput, setConditionInput] = useState(sessionStorage.getItem('condition') || '');
  const [categoryInput, setCategoryInput] = useState(sessionStorage.getItem('categories') || '');
  const [searchText, setSearchText] = useState(sessionStorage.getItem('search') || '');
  const [keywords, setKeywords] = useState([]);
  const [lastSearchParams, setLastSearchParams] = useState({
    projects: '',
    condition: '',
    categories: '',
    search: '',
    type: '',
  });

  const [keywordMessage, setKeywordMessage] = useState('Search for keywords');
  const [projectInputFocused, setProjectInputFocused] = useState(false);
  // pagination
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('currentPage') || 1);
  const [totalPages, setTotalPages] = useState(0);
  // chart data
  const [searchVolLoading, setSearchVolLoading] = useState(false);
  const [searchVolError, setSearchVolError] = useState(null);
  const [searchVolData, setSearchVolData] = useState({});

  const categories = type === 'Brand Project' ? branded_categories : unbranded_categories;
  const projectOrCondition = type === 'Brand Project' ? projects : conditions;

  const onProjectFocus = () => {
    setProjectInputFocused(true);
  };

  const onProjectBlur = (e) => {
    // Delay the blur event to let the click event register first
    setTimeout(() => {
      setProjectInputFocused(false);
      setFilteredProjects([]);
    }, 100);
  };

  // Handle project input change and filter suggestions
  const handleProjectInputChange = (e) => {
    const inputValue = e.target.value;
    sessionStorage.setItem('project', inputValue);
    setProjectInput(inputValue);
    
    // Filter projects based on input value
    const suggestions = projectOrCondition.filter(project =>
      project.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredProjects(suggestions);
  };

  const handleConditionInputChange = (e) => {
    const inputValue = e.target.value;
    sessionStorage.setItem('condition', inputValue);
    setConditionInput(inputValue);
    
    // Filter projects based on input value
    const suggestions = projectOrCondition.filter(project =>
      project.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredProjects(suggestions);
  }

  const handleTypeSelect = (e) => {
    setType(e.target.value);
    sessionStorage.setItem('type', e.target.value);

    // clear project and category inputs
    setProjectInput('');
    setCategoryInput('');
    sessionStorage.setItem('project', '');
    sessionStorage.setItem('categories', '');
  }

  // Handle selection from the autosuggestion list
  const handleItemSelect = (item) => {
    if(type === 'Brand Project') {
    setProjectInput(item);
    sessionStorage.setItem('project', item);
    setFilteredProjects([]); // Clear suggestions after selection
    }

    if(type === 'Condition') {
      setConditionInput(item);
      sessionStorage.setItem('condition', item);
      setFilteredProjects([]); // Clear suggestions after selection
    }
  };

  // Handle category selection
  const handleCategorySelect = (e) => {
    setCategoryInput(e.target.value);
    sessionStorage.setItem('categories', e.target.value);
  };

  // Handle search text change
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    sessionStorage.setItem('search', e.target.value);
  };

  // Handle search form submission
  const handleSearch = async (e) => {
    e.preventDefault();

    setSearching(true);
    setSearchVolLoading(true);
    setSearchVolError(null);
    setKeywordMessage('');

    // Save search in session storage
    sessionStorage.setItem('type', type);
    sessionStorage.setItem('search', searchText);
    sessionStorage.setItem('project', projectInput);
    sessionStorage.setItem('condition', conditionInput);
    sessionStorage.setItem('categories', categoryInput);

    try {
      const res = await $axios.get('/derived_keywords_list/', {
        params: {
          projects: projectInput,
          condition: conditionInput,
          categories: categoryInput,
          search: searchText,
          type: type,
        },
      });
      
      setKeywords(res.data.results);
      setTotalPages(Math.ceil(res.data.count / 500));
      setLastSearchParams({
        projects: projectInput,
        condition: conditionInput,
        categories: categoryInput,
        search: searchText,
        type: type,
      });

      if (res.data.results.length === 0) {
        setKeywordMessage('No keywords found');
        setSearchVolLoading(false); // Stop loading if no keywords found
        return; // Exit early if no keywords
      }

      // Proceed to fetch chart data without blocking
      fetchChartData();

    } catch (err) {
      console.error(err);
    } finally {
      setSearching(false);
    }
  };

  // Fetch chart data asynchronously
  const fetchChartData = async () => {
    try {
      const chartRes = await $axios.get('/derived_keywords_list_charts/', {
        params: {
          projects: projectInput,
          condition: conditionInput,
          categories: categoryInput,
          search: searchText,
        },
      });

      const data = chartRes.data;
      data.params = {
        projects: projectInput,
        condition: conditionInput,
        categories: categoryInput,
        search: searchText,
        type: type,
      }

      setSearchVolData(data);
    } catch (err) {
      console.error(err);
      setSearchVolError(err);
    } finally {
      setSearchVolLoading(false);
    }
  };

  useEffect(() => {
    if (type!== '' && projectInput !== '' && categoryInput !== '') {
      handleSearch({ preventDefault: () => {} }); // Trigger search after setting state
    }
  }, []);

  useEffect(() => {
    setProjectInput('');
    setConditionInput('');
    sessionStorage.setItem('project', '');
    sessionStorage.setItem('condition', '');
  }, [type]);

  useEffect(() => {
    const handleNextPage = async () => {
      setSearching(true);
  
      try {
        const res = await $axios.get('/derived_keywords_list/', {
          params: {
            page: currentPage,
            projects: projectInput,
            categories: categoryInput,
            search: searchText,
            type: type,
          }
        });
        setKeywords(res.data.results);
        setTotalPages(Math.ceil(res.data.count / 500));
  
        if (res.data.results.length === 0) {
          setKeywordMessage('No keywords found');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSearching(false);
      }
    };

    if (currentPage >= 1 && !searching && keywords.length > 0) {
      handleNextPage();
    }
  }, [currentPage]);

  if (loading) return <Loading />

  if (error) {
    return <div className="text-center">
      <h1>Error fetching data</h1>

      <p className="text-red-500">{error.message}</p>
    </div>
  }

  return (
    <>
      <div className="admin-page keyword-universe page-base">
        <nav aria-label="breadcrumbs">
          <ol className="flex gap-2 text-xs md:text-sm items-center">
            <li>
              <Link to="/admin">Admin</Link>
              <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
            </li>
            <li>
              <Link to="/admin/library-catalog">Library Catalog</Link>
              <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
            </li>
            <li className="font-bold text-purple-400 text-lg">
              Keyword Universe
            </li>
          </ol>
        </nav>

        <div>
          <h1 className="mb-2">Keyword Universe</h1>

          <section>
            <form className="search-form" onSubmit={handleSearch}>
              <label className="form-group type" htmlFor="type">
                <select 
                  name="type" 
                  id="type"
                  value={type}
                  onChange={handleTypeSelect}
                >
                  <option value="">Select Type</option>
                  {["Brand Project", "Condition"].map((type) => {
                    return <option key={type} value={type}>{type}</option>
                  })}
                </select>
              </label>

              <label className="form-group projects relative">
                { type ==="Brand Project" 
                  ? <input
                      type="text"
                      name='projects'
                      id='projects'
                      placeholder="Search Brand Projects"
                      value={projectInput}
                      onChange={handleProjectInputChange}
                      onFocus={onProjectFocus}
                      onBlur={onProjectBlur}
                      autoComplete="off"
                      disabled={type === ''}
                    />
                  : <input
                      type="text"
                      name="conditions"
                      id="conditions"
                      placeholder="Search Conditions"
                      value={conditionInput}
                      onChange={handleConditionInputChange}
                      onFocus={onProjectFocus}
                      onBlur={onProjectBlur}
                      autoComplete="off"
                      disabled={type === ''}
                    />
                }
                {projectInputFocused && filteredProjects.length > 0 && (
                  <ul className="suggestions-list">
                    {filteredProjects.map((item, index) => (
                      <li
                        key={index}
                        onMouseDown={(e) => e.preventDefault()} // Prevent blur before selection
                        onClick={() => handleItemSelect(item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </label>

              <label className="form-group categories" htmlFor="categories">
                <select 
                  name="categories" 
                  id="categories"
                  value={categoryInput}
                  onChange={handleCategorySelect}
                  disabled={
                    type === '' 
                    || type === "Brand Project" && projectInput === '' 
                    || type === "Condition" && conditionInput === ''}
                >
                  <option value="">All Categories</option>
                  {categories.map((category) => {
                    return <option key={category} value={category}>{category}</option>
                  })}
                </select>
              </label>

              <label className="form-group search" htmlFor="search">
                <input
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearchTextChange}
                  disabled={type === ''}
                  autoComplete="off"
                />
              </label>

              <button type="submit" disabled={!(projectInput || conditionInput) ||searching || searchVolLoading}>
                { searching
                  ? <img src={spinner} alt="Loading..." className="w-6 h-6 mx-auto" />
                  : 'Search'
                }
              </button>
            </form>
          </section>
        </div>

        { keywords.length > 0 
          ? <section>
              <SearchVolMonth data={searchVolData} loading={searchVolLoading} error={searchVolError} />
          </section> 
          : null
        }

        <div id="table-container" className="relative overflow-scroll section-bg-light dark:section-bg-dark">
          {
            searching && <div className="loading-overlay absolute inset-0 bg-black bg-opacity-50 z-50 grid place-items-center">
              <img src={spinner} alt="Loading..." className="w-12 h-12 mx-auto" /> 
            </div>
          }

          { keywords.length === 0 
            ? <p className="h-[550px] text-center content-center p-4">{keywordMessage}</p>
            : <>
                <KeywordsTable type={lastSearchParams.type} keywords={keywords} />

                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                  loading={searching}
                  setLoading={setSearching}
                  error={error}
                />
              </>
          }
        </div>
      </div>
    </>
  );
}

export default KeywordUniverse;
