// react
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// components
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import RefreshButton from '../../components/RefreshButton/RefreshButton';
import KeywordsChart from '../../components/charts/KeywordsChart';

// styles
import './ProjectKeywords.css';

const ProjectKeywords = () => {
  const location = useLocation();
  const { 
    project, 
    tkwl,
    kwl_summary
  } = location.state;
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  
  const handleChange = (value, projectId) => {
    setSearchQuery(value);
    // fetchFilteredData(searchQuery, projectId);
  };

  const handleSubmit = (e, projectId) => {
    e.preventDefault();
    // fetchFilteredData(searchQuery, projectId);
  };

  return <div className="project-keywords page-base">
    <section className="keyword-opportunity-section">
      <Breadcrumbs
        project={project}
        currentPage="Keyword Opportunities"
      />

      <div className="page-header">
        <h1 className='grad'>{ project.name }</h1>
      </div>

      <div className="flex gap-4 items-center mb-2">
        <h2>Keyword Opportunities</h2>
        <RefreshButton
          refresh_property="keywordList"
          project={project}
        />
      </div>

      <div className='section-bg-light dark:section-bg-dark p-2 md:p-4'>
        <div className="keyword-search">
          <form onSubmit={e => handleSubmit(e, project.uuid)}>
            <input
            type="text"
            placeholder="Search for a keyword"
            value={searchQuery}
            onChange={e => handleChange(e.target.value, project.uuid)}
            />
            <button>submit</button>
          </form>
        </div>

        <KeywordsChart data={ tkwl } />
      </div>

    </section>

    <section className="insights">
      <h2 className='mb-2'>Insight Analysis</h2>

      <div className='space-y-4'>
      { kwl_summary?.split("\n\n").map((paragraph, index) => <p key={index}>{paragraph}</p>) }
      </div>
    </section>
  </div>
}

export default ProjectKeywords;