import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { useEffect, useState } from "react";
import { useTheme } from "../../../../utils/ThemeManager.jsx";
import { getChartColors } from "../../../../utils/chartUtils.js";

Highcharts.themeDark = {
  colors: getChartColors('dark'),
};

Highcharts.themeLight = {
  colors: getChartColors('light'),
}

Highcharts.setOptions({
	plotOptions: {
		series: {
			animation: false
		}
	}
});

// data: brandData.side_effect_plot
const TolerabilityChart = ({ project, data }) => {
  const { theme } = useTheme();

  useEffect(() => {
    theme === 'dark'
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight)
  }, [theme]);

  if (!data || !Object.keys(data).length) {
    return <p className="px-4 py-8 font-bold grid place-content-center">Tolerability data is not available.</p>
  }

  return <ChartDashboard config={pieOptions(project, data, theme)} id="tolerability-chart-container" />;
};

export default TolerabilityChart;

// chart options
const pieOptions = (project, data, theme) => {
  const keys = Object.keys(data);
  const values = Object.values(data);
  const percentages = values.map((value) => value / values.reduce((a, b) => a + b, 0) * 100);

  return {
  gui: {
    layouts: [
      {
        rows: [{
          cells: [{
            id: `tolerability-cell-0`,
          }]
        }]
      }
    ],
  },
  components: [
    {
      type: "Highcharts",
      cell: `tolerability-cell-0`,
      chartOptions: {
        chart: {
          height: 460,
          styledMode: false,
          type: 'pie',
          marginTop: 110
      },
        title: {
          text: 'Proportion of Side Effects vs. Brand',
          style: {
            color: theme === 'dark' ? 'white' : 'black',
          }
        },
        subtitle: {
          text: percentages[1] 
            ? `BRAND Side Effects makes up ${ percentages[1].toFixed(2) }% of searches when compared to the volume of the brand name, ${ project.name }.`
            : 'No data available',
          style: {
            color: theme === 'dark' ? 'white' : 'black',
            opacity: 0.8,
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          align: 'center',
          verticalAlign: 'top',
          layout: 'horizontal',
          itemStyle: {
            color: theme === 'dark' ? 'white' : 'black',
            font: '14px Trebuchet MS, Verdana, sans-serif',
            fontWeight: 'bold'
          }
        },
        plotOptions: {
          pie: {
            showInLegend: true,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              distance: -60,
              enabled: true,
              format: '{point.percentage:.2f}%',
              style: {
                color: theme === 'dark' ? 'white' : 'black',
                fontSize: '1.4rem',
              }
            }
          }
        },
        series: [
          {
            name: 'Search Volume',
            type: 'pie',
            data: [
              {
                name: keys[0] || 'Medication',
                y: percentages[0] || 0,
              },
              {
                name: keys[1] || 'Side Effect',
                y: percentages[1] || 0,
              },
            ],
          },
        ],
      }
    }
  ]
  }
}