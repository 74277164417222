// react
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import Markdown from "react-markdown";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faChartPie,
  faUpRightFromSquare,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

// hooks
import useProject from "../../hooks/useProject";
import useBrandAnalysis from "../../hooks/useBrandAnalysis";

// global components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loading from "../../components/Loading/Loading";
import RefreshButton from "../../components/RefreshButton/RefreshButton";

// local components
import ACNUInfo from "./ACNUInfo/ACNUInfo";
import AnnualForecast from "./AnnualForecast/AnnualForecast";
import MedicationOverview from "./MedicationOverview/MedicationOverview";
import SearchVolMonthType from "./SearchVolMonthType/SearchVolMonthType";
import PrescriptionTrend from "./PrescriptionTrend/PrescriptionTrend";
import DirectCompetitorChart from "../CompetitiveAnalysis/DirectCompetitorChart";

// styles
import "./Project.css";

function isLongerThanDate(date) {
  // threshhold date is sept 1 2024
  const threshhold = new Date("2024-09-01");
  const lastRun = new Date(date);

  return threshhold - lastRun > 30 * 24 * 60 * 60 * 1000;
}

const Project = () => {
  const navigate = useNavigate();
  const uuid = useParams().projectId;
  const { project, loading: loadingProject } = useProject(uuid, navigate);
  const { brandData, loading: loadingBrand } = useBrandAnalysis(uuid, navigate);

  useEffect(() => {
    // if no uuid, redirect to companies page
    if (!uuid) {
      navigate("/companies");
      console.error("Redirecting due to missing company or project");
      return;
    }
  }, [uuid, navigate]);

  if(loadingProject || loadingBrand) {
    return <Loading />
  }

  if(!project?.condition_analysis_name) {
    return <div className="flex flex-col justify-center items-center">
      <p className="px-4 py-8 font-bold grid place-content-center">This project cannot be displayed because it does not have an associated condition.</p>
      <button className="bg-pink-500 p-4 rounded w-full min-w-[500px]" onClick={() => navigate("/companies")}>Back to Companies</button>
    </div>
  }

  if(isLongerThanDate(project.last_run)) {
    // only projects that have a condition analysis and have 
    return <div className="flex flex-col justify-center items-center space-y-8">
      <h1 className="px-4 py-8 text-2xl font-bold grid place-content-center">This project must be refreshed prior to viewing</h1>
      <div className="text-5xl">
        <RefreshButton
          refresh_property="project"
          project={project}
          project_eligible_for_analysis={
            project.eligible_for_analysis
          }
        />
      </div>

      <div>
        <Link to="/companies" className="rounded font-bold w-full min-w-[500px] mt-4">Back to Companies</Link>
      </div>

      <div>
        <Link to="/project-status" className="p-4 font-bold rounded w-full min-w-[500px] mt-4">View Project Status</Link>
      </div>
    </div>
  }

  return (
    <div className="project-page page-base">
      <section className="general" aria-busy={loadingProject}>
        <Breadcrumbs project={project} />

        <div className="page-header">
          <div className="content">
            <div className="flex flex-col md:flex-row md:gap-4 md:items-center col-span-1">
              <div className="grid grid-rows-2">
                <div className="flex gap-4 items-center">
                  <h1 className="grad">{project.name}</h1>
                  <Link to={`/project-edit`} state={{ project }}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>

                  <RefreshButton
                    refresh_property="project"
                    project={project}
                    project_eligible_for_analysis={
                      project.eligible_for_analysis
                    }
                  />
                </div>

                {project.url && (
                  <a href={project.url} target="_blank" rel="noreferrer">
                    {project.url}
                    <FontAwesomeIcon
                      className="ml-2"
                      icon={faUpRightFromSquare}
                      size="xs"
                    />
                  </a>
                )}
              </div>

              <div className="acnu-section">
                {uuid === "e8d32602-0897-44a4-a14a-5152c1d44fdc" ? (
                  <Link
                    to={`/project/${uuid}/acnu_dashboard`}
                    state={{ project }}
                  >
                    ACNU Dashboard
                    <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                  </Link>
                ) : (
                  <button disabled>
                    ACNU Dashboard
                    <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                  </button>
                )}
              </div>
            </div>

            <ACNUInfo project={project} loading={loadingProject} />
          </div>
        </div>
      </section>

      <section className="medication-overview" aria-busy={loadingBrand || loadingProject}>
        <h2 className="mb-2">Overview of {project.name}</h2>

        <MedicationOverview project={project} brand={brandData} loading={loadingBrand || loadingProject} />
      </section>

      <section className="prescription-trend" aria-busy={loadingProject}>
      <h2 className="mb-2">
        { project?.condition_analysis_name 
          ? `Overview of ${project?.condition_analysis_name}` 
          : "Condition Overview"
        }
      </h2>

      <div className="section-bg-light dark:section-bg-dark p-4 space-y-4">
        { project?.condition_analysis?.frontpage_summary
          ? <div className="space-y-2"><Markdown>{ project?.condition_analysis?.frontpage_summary }</Markdown></div>
          : <p>Summary is not available.</p> 
        }

        { project?.condition_analysis?.population_chart_input 
          ? <PrescriptionTrend data={project.condition_analysis.population_chart_input} />
          : <p className="p-4 md:p-8 text-center">Population chart is not available.</p>
        }
      </div>

        <div className="flex justify-end">
          {/* <Link className="px-4 py-2 flex gap-2 items-center" to={`/project/${uuid}/condition-analysis`} state={{ project }}>
            Condition Analysis
            <FontAwesomeIcon icon={faChevronRight} />
          </Link> */}
          <button className="px-4 py-2 flex gap-2 items-center opacity-50 cursor-not-allowed" disabled>
            Condition Analysis
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </section>

      <section className="consumer-journey" aria-busy={loadingProject}>
        <h2 className="mb-2">Keyword Universe</h2>
        <div className="section-bg-light dark:section-bg-dark p-4 space-y-6">
          {/* { project?.consumer_journey_summary
            ? <div className="space-y-2"><Markdown>{ project?.consumer_journey_summary }</Markdown></div>
            : <p>Consumer Journey summary is not available.</p> 
          } */}

          <SearchVolMonthType data={ project?.keyword_universe } />
        </div>

        <div className="flex justify-end">
          <Link className="px-4 py-2 flex gap-2 items-center" to={`/project/${uuid}/keyword-universe`}>
            Keyword Universe
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </div>
      </section>

      <section className="forecast" aria-busy={loadingProject}>
        <h2 className="mb-2">ACNU Forecast</h2>

        <div className="section-bg-light dark:section-bg-dark p-4 space-y-10">
          <AnnualForecast data={project?.keyword_universe} />
        </div>

        <div className="flex justify-end">
          <Link className="px-4 py-2 flex gap-2 items-center" to={`/project/${uuid}/acnu-feasibility`}>
            ACNU Feasibility
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </div>
      </section>

      <section className="competitive" aria-busy={loadingBrand}>
        <h2 className="mb-2">Competitive Analysis</h2>

        {
          brandData && brandData.competitors?.length > 0 
            ? <DirectCompetitorChart data={brandData.competitors} />
            : <p>No competitor data available at this time</p>
        }

      <div className="flex justify-end">
        <Link 
          className="px-4 py-2 flex gap-2 items-center"
          to={`/project/${uuid}/competitive-analysis#competitive-landscape`}>
          Competitive Analysis
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
      </section>

      {/* <SerpAnalysis data={ project } /> */}
    </div>
  );
};

export default Project;