import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import spinner from '../../../../assets/img/spinner.svg'

// data: branded_search_volume, condition_search_volume
// Search Volume by Month by Type
const SearchVolMonth = ({ data, loading, error }) => {
  if (loading) {
    return (
      <div className="min-h-[550px] z-50 grid place-items-center">
        <img src={spinner} alt="Loading..." className="w-12 h-12 mx-auto" />
      </div>
    );
  }

  if (error) {
    return <div className="h-[550px] content-center space-y-4 px-4 py-8 font-bold text-center">
      <h1>An error occurred while fetching Monthly Search Volume data.</h1>
      { error.message ? <p className="font-mono">{error.message}</p> : null }
    </div>
  }

  if (!data || Object.keys(data).length === 0) {
    return <p className="px-4 py-8 font-bold text-center">Monthly Search Volume data is not available.</p>
  }

  return <ChartDashboard config={chartOptions(data)} id="monthly-search-volume" />;
};

export default SearchVolMonth;

function formatSubtitle(params) {
  let subtitleText = `Source: <a style="color: #aaa;" target="_blank" href="https://semrush.com">SEMRush</a>&nbsp;&nbsp;`;

  if (params.type) {
    subtitleText += `&nbsp;&nbsp;Type: ${params.type}&nbsp;&nbsp;`;
  }

  if (params.projects) {
    subtitleText += `&nbsp;&nbsp;Project: ${params.projects}&nbsp;&nbsp;`;
  }

  if (params.categories) {
    subtitleText += `&nbsp;&nbsp;Category: ${params.categories}&nbsp;&nbsp;`;
  }

  if (params.search) {
    subtitleText += `&nbsp;&nbsp;Query: ${params.search}&nbsp;&nbsp;`;
  }

  return subtitleText;
}

const chartOptions = ({ branded_search_volume, condition_search_volume, params }) => {
  const formatData = (data) => {
    return Object.entries(data).map(([dateString, value]) => {
      const [year, month] = dateString.split('-').map(Number);
      return [Date.UTC(year, month - 1, 1), value]; // month is 0-indexed in Date.UTC
    });
  };

  let series, seriesName
  if(params.type === 'Brand Project') {
    series = formatData(branded_search_volume);
    seriesName = 'Branded Search Volume';
  }

  if(params.type === 'Condition') {
    series = formatData(condition_search_volume);
    seriesName = 'Condition Search Volume';
  }

  const subtitleText = formatSubtitle(params);

  return {
      gui: {
        layouts: [
          {
            rows: [
              {
                cells: [
                  {
                    id: "monthly-search-volume-cell",
                  },
                ],
              },
            ],
          },
        ],
      },
      components: [
        {
          type: "Highcharts",
          cell: "monthly-search-volume-cell",
          chartOptions: {
            chart: {
                type: 'column',
                styledMode: false,
                height: 550,
                marginBottom: 80,
            },
            title: {
                text: 'Search Volume by Month by Type',
                align: 'left',
            },
            subtitle: {
              text: subtitleText,
              align: 'center',
              verticalAlign: 'bottom',
              y: 25,
          },
              legend: {
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal',
                backgroundColor: 'rgb(248,248,248)',
                borderWidth: '1',
                borderColor: 'rgb(230,230,230)',
            },
            xAxis: {
              type: 'datetime',
              accessibility: {
                rangeDescription: 'Range: 12 months.'
              },
              dateTimeLabelFormats: {
                month: '%b' // Display abbreviated month name
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Monthly Search Volume',
              },
            },
            tooltip: {
              useHTML: true,
              shadow: false,
              borderWidth: 2,
              headerFormat: '<table>',
              pointFormat: '<tr><th>{point.x:%b}</th></tr>' +
              '<tr><th>Monthly Volume:</th><td>{point.y}</td></tr>',
              footerFormat: '</table>',
              style: {
                color: 'contrast',
              }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    pointPadding: 0,
                    groupPadding: .145,
                    /*animation: {
                        duration: 2000
                    },*/
                },
                
            },
            series: [
                {
                    name: seriesName,
                    data: series,
                    color: {
                      linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                      },
                      stops: [
                        [0, 'rgb(255, 20, 145, 1)'],
                        [.999, 'rgb(255, 114, 71, 1)']
                      ]
                  }, //end color
                  /*borderColor: '#303030',
                  borderWidth: 1,*/
                },
            ]
          }
        },
      ],
  }
}