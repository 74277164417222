// react
import { Link } from "react-router-dom";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

// global components
import RefreshButton from "../RefreshButton/RefreshButton";

// local components
import ProjectDifficultyIcon from "./ProjectDifficultyIcon";
import ProjectStatusIcon from "./ProjectStatusIcon";

// styles
import "./ProjectList.css";

const ProjectList = ({ company, projects = [], selectedDifficulties, projectStatus = [] }) => {
  // Add an isUpdating property to projects
  projects = projects.map(project => {
    const projectStatusIndex = projectStatus.findIndex(status => status.id === project.id);

    if(projectStatusIndex !== -1) {
      return projectStatus[projectStatusIndex];
    }

    return project;
  });

  // Filter projects based on selected difficulties
  const filteredProjects = projects.filter(project =>
    selectedDifficulties[project.acnu_difficulty] || Object.values(selectedDifficulties).every(v => !v) || !project.acnu_difficulty
  );


  return (
    <table className="project-table">
      <thead>
        <tr>
          <th>Project</th>
          <th className="flex justify-start items-center gap-4">
            ACNU Difficulty
          </th>
          <th>
            <span>
              <span className="hidden md:block">Last Run</span>
              <span className="block md:hidden text-center">Status</span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        { filteredProjects.map((project, index) => (
            <tr key={project.uuid || index} className={ project.last_ran === 'in progress' ? 'animate-pulse' : ''}>
              <td>
                <div className="project-header">
                  <div>
                  <h3>
                    {
                      project.condition_analysis_name 
                      ? <Link 
                        to={`/project/${project.uuid}`}
                        state={{ company, project }}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {project.name} 
                      </Link>
                      : project.name
                    }

                  </h3>
                  { project.condition_analysis_name 
                    ? <div className="inline-flex py-0.5 px-2 text-xs bg-gray-500 text-white rounded-sm rounded-tl-none ring-2 ring-gray-500 ring-offset-1 ring-offset-white">
                      <span>{project.condition_analysis_name}</span>
                    </div>
                    : <div className="inline-flex py-0.5 px-2 text-xs bg-red-500 bg-opacity-50 text-white rounded-sm rounded-tl-none ring-2 ring-red-500 ring-opacity-50 ring-offset-1 ring-offset-white">
                    <span>unavailable</span>
                  </div>}
                  </div>

                  <Link 
                    className="refresh-btn" to={`/project-edit/`}
                    state={{ company, project }}
                    >
                    <FontAwesomeIcon icon={faEdit} />
                    <span className="sr-only">Edit Project</span>
                  </Link>
                  <RefreshButton 
                    refresh_property="project"
                    project={project}
                  />
                </div>
              </td>
              <td>
                <ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} />
              </td>
              <td>
                <div className="flex items-center justify-center md:justify-start md:gap-4">
                  {/* 
                  STATUS_CHOICES = [
                    ("Never Run", "Never Run"),
                    ("Queued", "Queued"),
                    ("In Progress", "In Progress"),
                    ("Success", "Success"),
                    ("Failure", "Failure"), */
                  }
                  <div className="icon-bg">
                    <ProjectStatusIcon status={project.processing_status} />
                  </div>
                  
                  <span className="hidden md:block">
                    {
                      project.last_run  
                      ? new Date(project.last_run).toLocaleDateString() 
                      : 'Never'
                    }

                  </span>
                </div>
              </td>
              <td className="spacer" role="presentation" aria-hidden="true"></td>
            </tr>
          )) 
        }
      </tbody>
    </table>
  );
};

export default ProjectList;