import { Suspense, useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../../context/AuthContext.jsx'
import { Environment, Lightformer } from '@react-three/drei'

import { $axios, hasNonExpiredToken } from '../../services/axiosInstance';

import google from '../../assets/img/google.svg'
import logo from '../../assets/img/sunrise.svg'
import logoOrange from '../../assets/img/sunrise-orange.svg'

import Sunrise from '../../canvas/Sunrise.jsx';

import { useTheme } from '../../utils/ThemeManager';

// import zustand store
import session from '../../stores/session'

import './SignIn.css';

const SignIn = () => {
  const { user, setUser, clearUser } = session();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const { signInWithGoogle, logout } = UserAuth()
  const { theme } = useTheme()

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { user: googleUser } = await signInWithGoogle();
      if (googleUser.email.includes("@idea-evolver.com")) {
        setUser(googleUser);

        if (hasNonExpiredToken(googleUser)) {
          await $axios.post('/token_check/', { token: googleUser.accessToken });
          
          // check for a redirect path
          const redirectPath = sessionStorage.getItem('redirectPath');
          if (redirectPath) {
            navigate(redirectPath, { replace: true });
            sessionStorage.removeItem('redirectPath');
          } else {
            navigate('/companies', { replace: true });
          }
        } else {
          throw new Error("Token expired or invalid.");
        }
      } else {
        logout();
        alert("You are not authorized to use this app. Please contact the admin.");
      }
    } catch (error) {
      console.error("Error signing in:", error);
      alert("An error occurred during sign-in. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      $axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    const verifyToken = async () => {
      setLoading(true);
      const userExists = user && Object.keys(user).length > 0;
      try {
        if (userExists && hasNonExpiredToken(user)) {
          await $axios.post('/token_check/', { token: user.accessToken });
          navigate('/companies', { replace: true });
        } else {
          throw new Error("Token expired or invalid.");
        }
      } catch (error) {
        clearUser();
        logout();
        alert("You are not authorized to use this app. Please contact the admin.");
      } finally {
        setLoading(false);
      }
    };

    if (user && Object.keys(user).length > 0) verifyToken();
  }, []);

  return (
    <div className='signin-page'>
      <Canvas
        orthographic
        camera={{ position: [-1, -1, 5], zoom: 5000 }}
        style={{ zIndex : 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      >
        <color attach="background" args={['#151518']} />
        {/* <color attach="background" args={['#fef4ef']} /> */}
        <ambientLight />
        <directionalLight castShadow intensity={0.6} position={[0, 0, 10]} />

        <Suspense fallback={null}>
          <Sunrise scale={0.1} position-y={-0.02} />
        </Suspense>

        <Environment resolution={256}>
        <group rotation={[-Math.PI / 2, 0, 0]}>
          <Lightformer intensity={4} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} />
          {[2, 0, 2, 0, 2, 0, 2, 0].map((x, i) => (
            <Lightformer key={i} form="circle" intensity={4} rotation={[Math.PI / 2, 0, 0]} position={[x, 4, i * 4]} scale={[4, 1, 1]} />
          ))}
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={[50, 2, 1]} />
          <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} />
        </group>
      </Environment>
      </Canvas>

      <div className='overlay'>
        <img className="w-10" src={theme === 'dark' ? logo : logoOrange} alt="" />
        <div className=''>
          <h1 className=''>CodeScripts</h1>
          <p className=''>A New Horizon.</p>
          <p className=''>Empowering Self-Care.</p>
        </div>
      </div>
      
      <div className="sign-in">
        <div className="btn-container">
          <button className="btn" onClick={handleSubmit}>
            <img src={google} alt="Sign in with Google" />

            <div className='btn-border tl' role="presentation" />
            <div className='btn-border tr' role="presentation" />
            <div className='btn-border br' role="presentation" />
            <div className='btn-border bl' role="presentation" />

            <span className='sr-only'>Sign In With Google</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignIn