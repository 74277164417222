import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle, 
  faCircleCheck, 
  faCircleHalfStroke,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import './ProjectStatusIcon.css';

const ProjectStatusIcon = ({ status }) => {
  switch(status) {
    case 'In Progress':
    case 'Queued':
      return <FontAwesomeIcon icon={faCircleHalfStroke} className="text-yellow-600" title="In Progress" />;
    case 'Success':
      return <FontAwesomeIcon icon={faCircleCheck} className="text-green-700" title="Success" />;
    case 'Failure':
      return <FontAwesomeIcon icon={faCircleXmark} className="text-red-700" title="Failure" />;
    default:
      return <FontAwesomeIcon icon={faQuestionCircle} className="text-slate-600" title="Unknown" />;
  }
};

export default ProjectStatusIcon;