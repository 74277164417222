import { useEffect, useState } from "react";

import Highcharts, { chart } from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../../utils/ThemeManager.jsx";
import { getChartColors } from "../../../../utils/chartUtils.js";

Highcharts.themeDark = {
  colors: getChartColors('dark'),
};

Highcharts.themeLight = {
  colors: getChartColors('light'),
}

Highcharts.setOptions({
	plotOptions: {
		series: {
			animation: false
		}
	}
});

// data = brandData.stop_usage_activity
const StopUsageChart = ({ data }) => {
  const { theme } = useTheme();

  useEffect(() => {
    theme === 'dark'
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight)
  }, [theme]);

  return <ChartDashboard config={columnOptions(data)} id='stop-usage-chart-container' />
}

export default StopUsageChart;

// chart options
const columnOptions = (data) => ({
  gui: {
    layouts: [
      {
        rows: [
          {
            cells: [
              {
                id: "stop-usage-cell-0",
              },
            ],
          },
        ],
      },
    ],
  },
  components: [
    {
      type: "Highcharts",
      cell: "stop-usage-cell-0",
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: null,
          align: "left",
        },
        xAxis: {
          categories: data.xAxis_dates || [],
          type: "datetime",
        },
        yAxis: {
          min: 0,
          title: {
            text: "Approximate Monthly Search Volume",
          },
          stackLabels: {
            enabled: true,
          },
        },
        legend: {
          align: "top",
          x: 70,
          verticalAlign: "top",
          y: 10,
          floating: true,
          backgroundColor: "white",
          borderColor: "#CCC",
          borderWidth: 1,
          shadow: false,
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: data.data1_label,
            data: data.data1_values || [],
          },
          {
            name: data.data2_label,
            data: data.data2_values || [],
          },
          {
            name: data.data3_label,
            data: data.data3_values || [],
          }
        ],
      },
    },
  ],
})