import { useState, useEffect } from "react";
import { $axios } from "../../../../services/axiosInstance";

const useConditions = (url) => {
  const itemsPerPage = 10;
  const [conditions, setConditions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  
  useEffect(() => {
    const fetchConditions = async () => {
      setLoading(true);

      try {
        const { data } = await $axios.get(url)
        setConditions(data.results);
        setNext(data.next);
        setPrevious(data.previous);
        setCount(data.count);
        setTotalPages(Math.ceil(data.count / itemsPerPage));
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchConditions();
  }, [url]);

  return {
    conditions,
    loading,
    error,
    next,
    previous,
    count,
    totalPages,
  };
}

export default useConditions;