import './NewsItem.css'

const NewsItem = ({ news }) => {
  return (
    <li>
      <a className="news-item" href={news.article_url} target="_blank" rel="noreferrer">
      <div className="news-image">
          <img
            src={news.image_url}
            alt={news.title}
            referrerPolicy="no-referrer"
          />
        </div>

        <div className="news-body">
          <h3>{news.title}</h3>
          <div>
            <p className='author'>{news.author}</p>
            <p className="published">
              {news.published_utc
                ? new Date(news.published_utc).toLocaleDateString()
                : "Date not available"}
            </p>
          </div>
        </div>

      </a>
    </li>
  );
};

export default NewsItem;