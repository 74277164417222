import { useLocation } from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

import "./ConditionAnalysis.css"

const ConditionAnalysis = () => {
  const { project } = useLocation().state;

  return (
    <section className="condition-analysis-page page-base">
      <div>
        <Breadcrumbs project={project} currentPage="Condition Analysis" />

        <h1 className="text-3xl inline">{project && project.name}</h1>
        <p className="text-lg">Condition Analysis page is not implemented yet</p>
      </div>

    </section>
  );
}

export default ConditionAnalysis;