import { Link } from "react-router-dom";

import ProjectList from "../../components/ProjectList/ProjectList"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { useAppContext } from "../../context/AppContext";

export const filterUpdatingProjects = (updatingProjects, companyUuid) => {
  return updatingProjects.filter((p) => p.parent_company === companyUuid);
};

const CompanyItem = ({ company, selectedDifficulties }) => {
  // list of apps in queue
  const { updatingProjects } = useAppContext();
  const filteredUpdatingProjects = filterUpdatingProjects(updatingProjects, company.uuid);

  return <li className="company" key={`${company.uuid}`}>
  <div className="company-header">
    <h2 className="font-semibold">
      <Link to={`/company/${ company.uuid }`}>
        {company.name.length < 30 
          ? company.name 
          : `${company.name.substring(0, 27)}...`
        }
      </Link>
    </h2>

    <Link 
      to={`/company-edit/${company.uuid}`}
      state={{ company }}
    >
      <FontAwesomeIcon icon={faEdit} />
    </Link>
  </div>

  <div className="table-container overflow-x-scroll w-full">
    <ProjectList
      company={company}
      projects={company.brand_project}
      selectedDifficulties={selectedDifficulties}
      projectStatus={filteredUpdatingProjects}
    />
  </div>
</li>
}

export default CompanyItem