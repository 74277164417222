import Highcharts from "highcharts";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";

import { formatCurrency, formatPercent } from "../../../utils/tableUtils.js";

import './AnnualForecast.css';

// data: project.keyword_universe
function AnnualForecast({ data }) {
  const branded = data?.["Brand Subtotal"] ?? {};
  const condition = data?.["Condition Subtotal"] ?? {};

  const annualRevenue = [{
    name: 'Branded Revenue',
    data: [branded?.total_annual_revenue || 0]
  }, {
    name: 'Condition Revenue',
    data: [condition?.total_annual_revenue || 0] 
  }]

  const annualProfit = [{
    name: 'Annual Profit',
    data: [branded?.total_annual_profit || 0]
}, {
    name: 'Condition Profit',
    data: [condition?.total_annual_profit || 0]
}]

// if branded or condition is an empty object, return
if (Object.keys(branded).length === 0 || Object.keys(condition).length === 0) {
  return <>
    <div className="annual-forecast h-[550px] grid place-items-center">
      <p>Annual Forecast data is not available for this project.</p>
    </div>
  </>
}

return <div className="annual-forecast">
  <div className="content">
    <div className="col col-1">
      <h4 className="text-center font-bold p-2">Total Annual Revenue</h4>
      <p className="text-center text-2xl flex-1 grid place-items-center">{ formatCurrency(data['Grand Total'].total_annual_revenue) }</p>
      <h4 className="text-center font-bold p-2">Total Annual Profit</h4>
      <p className="text-center text-2xl flex-1 grid place-items-center">{ formatCurrency(data['Grand Total'].total_annual_profit) }</p>
      <h4 className="text-center font-bold p-2">Total Annual Profit Margin</h4>
      <p className="text-center text-2xl flex-1 grid place-items-center">{ formatPercent(data['Grand Total'].annual_profit_margin, 0) }</p>
    </div>
    <div className="col col-2">
      <ChartDashboard config={chartOptions(annualRevenue, 'revenue', 'Annual Revenue')} async={true} id="revenue-container" />
    </div>
    <div className="col col-3">
      <ChartDashboard config={chartOptions(annualProfit, 'profit', 'Annual Profit')} async={true} id="profit-container" />
    </div>
  </div>
</div>
} 

export default AnnualForecast;

const chartOptions = (data, name, title) => {


  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${name}-keywords-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${name}-keywords-cell`,
        chartOptions: {
          chart: {
              styledMode: false,
              type: 'column',
              height: 550,
              marginBottom: 100 // Adjust this value as needed
          },
          title: {
              text: title,
              align: 'center',
          },
          xAxis: {
            categories: [`${title}`],
          },
          yAxis: {
              min: 0,
              title: {
                  text: `${title} ($)`,
              },
              labels: {
                formatter: function () {
                    return Highcharts.numberFormat(this.value / 1000000, 0) + 'M';
                },
              },
              stackLabels: {
                enabled: true,
                formatter: function () {
                  return (this.total / 1000000).toFixed(1) + 'M'; // Changed precision to 1 and added 'M'
                },
              }
          },
          legend: {
            layout: 'horizontal',       // Display the legend in a horizontal layout
            align: 'center',            // Center the legend horizontally
            verticalAlign: 'bottom',    // Position the legend at the bottom
            y: 0,
          },
          tooltip: {
              headerFormat: null,
              // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
              pointFormatter: function () {
                return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${Highcharts.numberFormat(this.y, 0, '.', ',')}</b><br/>`;
              },
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return (this.y / 1000000).toFixed(1) + 'M'
                },
              }
            }
          },
          series: data
        },
      },
    ],
  };
};