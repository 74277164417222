import Highcharts from "highcharts";
import ChartDashboard from "../../../../../components/ChartDashboard/ChartDashboard.jsx";

import { formatCurrency } from "../../../../../utils/tableUtils.js";

import "./AnnualForecastByCategory.css";

// data: project.keyword_universe
function AnnualForecastByCategory({ data, category_type }) {
  const categories = Object.entries(data)
    .filter(([key, value]) => value.category_type === category_type)
    .map(([key]) => key);

  if (categories.length === 0) {
    return (
      <div className="forecast-branded h-[550px] grid place-items-center">
        <p>Forecast by { category_type } data is not available for this project</p>
      </div>
    );
  }

  return (
    <div className="forecast-branded">
      <div className="">
        <h3 className="font-bold">Annual Forecast by { category_type} Category</h3>
      </div>

      <div className="content">
        <div className="col col-1">
          <div>
            <h4 className="text-center font-bold p-2">
              { category_type } Profit Estimate
            </h4>
            <p className="text-center text-2xl flex-1 grid place-items-center pt-4">
              { formatCurrency(data['Brand Subtotal'].total_annual_profit, 0) }
            </p>
          </div>

          <div>
            <h4 className="text-center font-bold p-2">
              Profit by { category_type } Category
            </h4>
            <div className="pie-container">
              <ChartDashboard config={pieOptions(data, categories, category_type, `cost-purchase-${category_type}`)} id={`cost-purchase-${category_type}-container`} />
            </div>
          </div>
        </div>
        <div className="col col-2">
          <ChartDashboard config={barOptions(data, categories, category_type, `profit-${category_type}`)} id={`profit-${category_type}-container`} />
        </div>
      </div>
    </div>
  );
}

export default AnnualForecastByCategory;

const pieOptions = (data, categories, category_type, name) => {
  const totalCost = categories.reduce((sum, category) => {
    return sum + (data[category]?.total_annual_profit || 0);
  }, 0);

  const pieData = categories.map(category => {
    const cost = data[category]?.total_annual_profit || 0;
    return {
      name: category.split(' - ')[0],
      y: (cost / totalCost) * 100, 
    };
  });

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${name}-keywords-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${name}-keywords-cell`,
        chartOptions: {
          chart: {
            type: "pie",
            styledMode: false,
            height: 322,
            marginBottom: 10,
          },
          title: {
            text: null,
          },
          tooltip: {
            enabled: true,
            formatter: function () {
              const dollarValue = data[this.point.name]?.total_annual_profit || 0;
              return `<b>${this.point.name}</b>: ${formatCurrency(dollarValue, 0)}`;
            },
          },
          subtitle: {
            text: "",
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: [
                {
                  distance: -60,
                  enabled: true,
                  format: "{point.percentage:.1f} %",
                  style: {
                    color: 'contrast',
                    fontSize: "1.5rem",
                  },
                },
              ],
            },
          },
          series: [
            {
              name: "Percentage",
              type: "pie",
              data: pieData,
            },
          ],
        },
      },
    ],
  };
};

const barOptions = (data, categories, category_type, name) => {
  const barDataRevenue = categories.map(category => {
    return data[category]?.total_annual_revenue || 0;
  });

  const barDataProfit = categories.map(category => {
    return data[category]?.total_annual_profit || 0;
  });

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${name}-keywords-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${name}-keywords-cell`,
        chartOptions: {
          chart: {
            type: "bar",
            height: 484,
            marginRight: 70,
          },
          title: {
            text: `Revenue and Profit by ${ category_type } Category`,
            align: "left",
          },
          xAxis: {
            categories: categories,
            title: {
              text: null,
            },
            gridLineWidth: 1,
            lineWidth: 0,
          },
          yAxis: {
            min: 0,
            title: {
              text: null,
            },
            labels: {
              overflow: "justify",
            },
            title: {
              text: "Dollars (millions)",
              align: "high",
            },
            gridLineWidth: 0,
          },
          tooltip: {
            valueSuffix: " millions",
            // formatter 2 decimal places
            formatter: function () {
              return (
                "<b>" +
                this.x +
                "</b><br>" +
                this.series.name +
                ": <b>" + '$' +
                Highcharts.numberFormat(this.y, 0) +
                "</b>"
              );
            },
          },
          plotOptions: {
            bar: {
              borderRadius: "50%",
              dataLabels: {
                enabled: true,
                // format rounded to 0 decimal places
                formatter: function () {
                  return Highcharts.numberFormat(this.y, 0);
                },
              },
              groupPadding: 0.1,
            },
          },
          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "top",
            x: -10,
            y: 50,
            floating: true,
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: "Revenue",
              data: barDataRevenue,
            },
            {
              name: "Profit",
              data: barDataProfit,
            },
          ],
        },
      },
    ],
  };
};
