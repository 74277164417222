import Markdown from 'react-markdown'
import spinner from '../../../assets/img/spinner.svg';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons"

import Summary from '../../../components/Summary/Summary';

import './MedicationOverview.css';

function MedicationOverview({ project, brand, loading }) {
  if(loading) {
    return <div className="medication-overview-container">
      <div className="overview-bar loading">
        <img src={spinner} alt="Loading..." className="h-8 w-8" />
      </div>

      <div className="info-container loading">
        <img src={spinner} alt="Loading..." className="h-8 w-8" />
      </div>
    </div>
  }

  return (
    <div className="medication-overview-container">
      <div className="overview-bar">
        <div className="overview-item">
          <h3>Approval Category</h3>

          {project.acnu_features &&
            (Array.isArray(project.acnu_features) ? (
              project.acnu_features.length > 0 &&
              project.acnu_features[0].v1 &&
              project.acnu_features[0].v1.approval_category_main ? (
                <p className="capitalize">
                  {project.acnu_features[0].v1.approval_category_main.toLowerCase()}
                </p>
              ) : (
                <p>N/A</p>
              )
            ) : Object.keys(project.acnu_features).length > 0 ? (
              <p className="capitalize">
                {project.acnu_features.v1?.approval_category_main?.toLowerCase() ||
                  "N/A"}
              </p>
            ) : (
              <p>N/A</p>
            ))}
        </div>

        <div className="overview-item base">
          <h3>Active Ingredients</h3>

          {project.acnu_features &&
            (Array.isArray(project.acnu_features) ? (
              project.acnu_features.length > 0 &&
              project.acnu_features[0].v1 &&
              project.acnu_features[0].v1.active_ingredients ? (
                <p className="capitalize">
                  {project.acnu_features[0].v1.active_ingredients}
                </p>
              ) : (
                <p>N/A</p>
              )
            ) : Object.keys(project.acnu_features).length > 0 ? (
              <p className="capitalize">
                {project.acnu_features.v1.active_ingredients}
              </p>
            ) : (
              <p>N/A</p>
            ))}
        </div>

        <div className="overview-item base">
          <h3>Dosage Form</h3>

          {project.acnu_features &&
            (Array.isArray(project.acnu_features) ? (
              project.acnu_features.length > 0 &&
              project.acnu_features[0].v1 &&
              project.acnu_features[0].v1.dosage_form ? (
                <ul className="capitalize list-disc">
                  {project.acnu_features[0].v1.dosage_form
                    ?.split(", ")
                    .map((form, i) => (
                      <li key={i}>{form.toLowerCase()}</li>
                    ))}
                </ul>
              ) : (
                <p>N/A</p>
              )
            ) : Object.keys(project.acnu_features).length > 0 ? (
              <ul className="capitalize list-disc">
                {project.acnu_features.v1.dosage_form
                  ?.split(", ")
                  .map((form, i) => (
                    <li key={i}>{form.toLowerCase()}</li>
                  ))}
              </ul>
            ) : (
              <p>N/A</p>
            ))}
        </div>

        <div className="overview-item">
          <h3>Dosage Route</h3>

          {project.acnu_features &&
            (Array.isArray(project.acnu_features) ? (
              project.acnu_features.length > 0 &&
              project.acnu_features[0].v1 &&
              project.acnu_features[0].v1.dosage_route ? (
                <p className="capitalize">
                  {project.acnu_features[0].v1.dosage_route.toLowerCase()}
                </p>
              ) : (
                <p>N/A</p>
              )
            ) : Object.keys(project.acnu_features).length > 0 ? (
              <p className="capitalize">
                {project.acnu_features.v1.dosage_route.toLowerCase()}
              </p>
            ) : (
              <p>N/A</p>
            ))}
        </div>

        <div className="overview-item">
          <h3>
            Rare or Orphan
            <br />
            Disease Use
          </h3>

          {project.acnu_features &&
            (Array.isArray(project.acnu_features) ? (
              project.acnu_features.length > 0 &&
              project.acnu_features[0].v1 &&
              project.acnu_features[0].v1.rare_or_orphan_disease_use ? (
                <p className="capitalize">
                  {project.acnu_features[0].v1.rare_or_orphan_disease_use.toString()}
                </p>
              ) : (
                <p>N/A</p>
              )
            ) : Object.keys(project.acnu_features).length > 0 ? (
              <p className="capitalize">
                {project.acnu_features.v1.rare_or_orphan_disease_use.toString()}
              </p>
            ) : (
              <p>N/A</p>
            ))}
        </div>

        <div className="overview-item">
          <h3>Biomarkers</h3>
          {project.acnu_features &&
            (Array.isArray(project.acnu_features) ? (
              project.acnu_features.length > 0 &&
              project.acnu_features[0].v1 &&
              project.acnu_features[0].v1.biomarkers ? (
                <p className="capitalize">
                  {project.acnu_features[0].v1.biomarkers.toString()}
                </p>
              ) : (
                <p>N/A</p>
              )
            ) : Object.keys(project.acnu_features).length > 0 ? (
              <p className="capitalize">
                {project.acnu_features.v1.biomarkers.toString()}
              </p>
            ) : (
              <p>N/A</p>
            ))}
        </div>
      </div>

      <div className="info-container">
        <Summary summary={brand.brand_summary} />
      </div>
    </div>
  );
}

export default MedicationOverview;
