import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard";

import { formatPercent } from "../../../../utils/tableUtils.js";

import './ConditionOpportunity.css';

// data: project.condition_analysis
function ConditionOpportunity({ data }) {
  if (!data || Object.keys(data).length === 0 || !data.population_chart_input) {
    return <p className="min-h-[550px] px-4 py-8 font-bold grid place-content-center">Search Spend Rec data is not available.</p>
  }

  return <div className="condition-opportunity">
    <div className="content">
      <div className="col col-1">
        <h4 className="text-center font-bold p-2">People with Condition</h4>
        <p className="text-center text-2xl flex-1 grid place-items-center">
          { 
            data.condition_data?.total_population ? data.condition_data.total_population.toLocaleString() : 'N/A' 
          }
        </p>
        <h4 className="text-center font-bold p-2">Estimated People Untreated</h4>
        <p className="text-center text-2xl flex-1 grid place-items-center">
          {/* untreated_population */
            data.condition_data?.untreated_population ? data.condition_data.untreated_population.toLocaleString() : 'N/A'
          }
        </p>
        <h4 className="text-center font-bold p-2">Percent Untreated</h4>
        <p className="text-center text-2xl flex-1 grid place-items-center">
          {/* pct_untreated */
            data.condition_data?.pct_untreated ? formatPercent(data.condition_data.pct_untreated, 0) : 'N/A'
          }
        </p>
      </div>
      <div className="col col-2">
        <ChartDashboard config={lineChartOptions(data.population_chart_input)} async={true} id="condition-opportunity-container" />
      </div>
    </div>
  </div>
} 

export default ConditionOpportunity;

// chart options
const lineChartOptions = (data) => {
  const series = [];

  // Check if the first series exists and is valid
  if (data.labels[0] && data.data1 && data.data1.length > 0) {
    series.push({
      name: data.labels[0],
      data: data.data1,
    });
  }
  
  // Check if the second series exists and is valid
  if (data.labels[1] && data.data2 && data.data2.length > 0) {
    series.push({
      name: data.labels[1],
      data: data.data2,
    });
  }
  return {
  gui: {
    layouts: [
      {
        rows: [
          {
            cells: [
              {
                id: "population-cell-0",
              },
            ],
          },
        ],
      },
    ],
  },
  components: [
    {
      type: "Highcharts",
      cell: "population-cell-0",
      chartOptions: {
        chart: {
          height: 550,
          styledMode: false,
        },
        title: {
          text: data.title || null,
          align: "left",
        },
        subtitle: {
          text: data.subtitle || null,
          align: "left",
        },
        yAxis: {
          title: {
            text: data.yAxisText || null,
          },
        },
        xAxis: {
          categories: data.year || [],
        },
        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom",
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 768,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
        series: series,
      },
    },
  ],
  }
};