import React, { useEffect } from "react";
import * as Dashboards from "@highcharts/dashboards/dashboards";

import Highcharts from "highcharts";
import highchartsExporting from "highcharts/modules/exporting"; //module for exporting chart
import highchartsMore from "highcharts/highcharts-more"; //needed for dumbbell chart and waterfall
import highchartsVenn from "highcharts/modules/venn"; //module for venn chart
import highchartsFunnel from "highcharts/modules/funnel"; //module for funnel chart
import highchartsSunburst from "highcharts/modules/sunburst";
import highchartsHeatmap from "highcharts/modules/heatmap";
import highchartsHistogram from "highcharts/modules/histogram-bellcurve.js"; //module for histogram chart
// import highchartsDumbbell from "highcharts/modules/dumbbell.js"; //module for dumbbell chart

import highchartsAccessibility from "highcharts/modules/accessibility";

import DataGrid from "@highcharts/dashboards/es-modules/DataGrid/DataGrid";
import DataGridPlugin from "@highcharts/dashboards/es-modules/Dashboards/Plugins/DataGridPlugin";

import HighchartsPlugin from "@highcharts/dashboards/es-modules/Dashboards/Plugins/HighchartsPlugin";

import { useTheme } from "../../utils/ThemeManager.jsx";
import { getChartColors } from "../../utils/chartUtils.js";

// Apply the theme
Highcharts.setOptions({

  chart: {
    backgroundColor: null,
    animation: false,
  },
  title: {
    style: {
      color: 'contrast',
    }
  },
  subtitle: {
    style: {
      color: "contrast",
      fontSize: "12px",
    },
  },
  xAxis: {
    title: {
      style: {
        color: 'contrast',
      }
    },
    labels: {
      style: {
        color: 'contrast',
      }
    },
    stackLabels: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        color: 'contrast',
        textOutline: 'none',
      }
    },
    style: {
      color: 'contrast',
    }
  },
  yAxis: {
    title: {
      style: {
        color: 'contrast',
      }
    },
    labels: {
      style: {
        color: 'contrast',
      }
    },
    stackLabels: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        color: 'contrast',
        textOutline: 'none',
      }
    },
  },
  legend: {
    itemStyle: {
      font: "10pt Trebuchet MS, Verdana, sans-serif",
      color: "contrast",
    },
    itemHoverStyle: {
      color: "#ccc",
    },
  },
  tooltip: {
    style: {
      color: 'contrast',
    }
  },
  plotOptions: {
    series: {
      animation: false
    },
    column: {
      dataLabels: {
        style: {
          fontSize: "16px",
          fontWeight: "bold",
          textOutline: 'none',
          color: "contrast",
        }
      }
    },
    pie: {
      dataLabels: {
        style: {
          color: "contrast",
          fontSize: "110px",
          textOutline: "none",
        },
      },
    },
  },
});

// highchartsExporting(Highcharts);
highchartsExporting(Highcharts);
highchartsVenn(Highcharts);
highchartsFunnel(Highcharts);
highchartsSunburst(Highcharts);
highchartsHeatmap(Highcharts);
highchartsHistogram(Highcharts);
// highchartsDumbbell(Highcharts);
highchartsMore(Highcharts);
highchartsAccessibility(Highcharts);

HighchartsPlugin.custom.connectHighcharts(Highcharts);
DataGridPlugin.custom.connectDataGrid(DataGrid);

Dashboards.PluginHandler.addPlugin(HighchartsPlugin);
Dashboards.PluginHandler.addPlugin(DataGridPlugin);

export default function ChartDashboard({ config, id = "container", async = false }) {
  const { theme } = useTheme();
  useEffect(() => {
    Highcharts.setOptions({
      colors: getChartColors(theme)
    });

    Dashboards.board(id, config, async);
  }, [theme, config]);

  return <div id={id} className={`highcharts-${theme}`}></div>;
}
