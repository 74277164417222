// react
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../../utils/ThemeManager.jsx";

// highcharts
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more"; //needed for dumbbell chart and waterfall
import highchartsVenn from "highcharts/modules/venn"; //module for venn chart
import highchartsFunnel from "highcharts/modules/funnel"; //module for funnel chart
import highchartsHistogram from "highcharts/modules/histogram-bellcurve.js"; //module for histogram chart
import highchartsDumbbell from "highcharts/modules/dumbbell.js"; //module for dumbbell chart
// import highchartsExporting from "highcharts/modules/exporting";
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";

import ChartDashboard from "../../components/ChartDashboard/ChartDashboard.jsx";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs.jsx";

import useProject from "../../hooks/useProject.js";

import "./ACNUDashboard.css";

// mock data
import { useACNUData } from "./tempHooks.js";

highchartsAccessibility(Highcharts);
// highchartsExporting(Highcharts);
highchartsVenn(Highcharts);
highchartsFunnel(Highcharts);
highchartsHistogram(Highcharts);
highchartsMore(Highcharts);
highchartsDumbbell(Highcharts);

// Define custom SVG symbols for left and right triangles
Highcharts.SVGRenderer.prototype.symbols.triangleLeft = (x, y, w, h) => [
  "M",
  x,
  y + h / 2,
  "L",
  x + w,
  y,
  "L",
  x + w,
  y + h,
  "Z",
];

Highcharts.SVGRenderer.prototype.symbols.triangleRight = (x, y, w, h) => [
  "M",
  x + w,
  y + h / 2,
  "L",
  x,
  y,
  "L",
  x,
  y + h,
  "Z",
];

const ACNUDashboard = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const uuid = useParams().projectId;
  const { project, loading } = useProject(uuid, navigate);

  const acnuData = useACNUData();

  const config = {
    gui: {
      layouts: [
        {
          id: "acnu-dashboard",
          rows: [
            // -------------- ROW 0 -------------- //
            // ------------ sunburst ------------- //
            {
              id: "acnu-row-00",
              cells: [
                {
                  id: "acnu-cell-sunburst",
                },

              ],
            },
            {
              id: "acnu-row-0",
              cells: [
                {
                  id: "acnu-cell-heat-map",
                },
              ],
            },
            // -------------- ROW 1 -------------- //
            // ----------- screen time ----------- //
            {
              id: "acnu-row-1",
              cells: [
                {
                  id: "acnu-cell-screen-time",
                },
              ],
            },
            // -------------- ROW 2 -------------- //
            // ----------- kickout zone ---------- //
            {
              id: "acnu-row-2",
              cells: [
                {
                  id: "acun-cell-kickout-zone",
                },
              ],
            },
            // -------------- ROW 3 -------------- //
            // ---- pregnant smoking alcohol ---- //
            {
              id: "acnu-row-3",
              cells: [
                {
                  id: "acnu-cell-pregnant",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "33.3%",
                    },
                    large: {
                      width: "33.3%",
                    },
                  },
                },
                {
                  id: "acnu-cell-smoking",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "33.3%",
                    },
                    large: {
                      width: "33.3%",
                    },
                  },
                },
                {
                  id: "acnu-cell-alcohol",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "33.3%",
                    },
                    large: {
                      width: "33.3%",
                    },
                  },
                },
              ],
            },
            // -------------- ROW 4 -------------- //
            // ---- cardiac total cholesterol ---- //
            {
              id: "acnu-row-4",
              cells: [
                {
                  id: "acnu-cell-cardiac",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "50%",
                    },
                    large: {
                      width: "50%",
                    },
                  },
                },
                {
                  id: "acnu-cell-total-cholesterol",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "50%",
                    },
                    large: {
                      width: "50%",
                    },
                  },
                },
              ],
            },
            // -------------- ROW 5 -------------- //
            // ----------- kickout funnel -------- //
            {
              id: "acnu-row-5",
              cells: [
                {
                  id: "acnu-cell-kickout-funnel",
                },
              ],
            },
            // -------------- ROW 6 -------------- //
            // ----------- age range ------------- //
            {
              id: "acnu-row-6",
              cells: [
                {
                  id: "acnu-cell-age-range",
                },
              ],
            },
            // -------------- ROW 7 -------------- //
            // ------------- reorder ------------- //
            {
              id: "acnu-row-7",
              cells: [
                {
                  id: "acnu-cell-reorder",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "60%",
                    },
                    large: {
                      width: "60%",
                    },
                  },
                },
                {
                  id: "acnu-cell-other-statin",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "40%",
                    },
                    large: {
                      width: "40%",
                    },
                  },
                },
              ],
            },
            // -------------- ROW 8 -------------- //
            // --------------- age --------------- //
            {
              id: "acnu-row-8",
              cells: [
                {
                  id: "acnu-cell-age-scatter",
                },
              ],
            },
            // -------------- ROW 9 -------------- //
            // -------------- beeswarm -------------- //
            {
              id: "acnu-row-9",
              cells: [
                {
                  id: "acnu-cell-beeswarm",
                },
              ],
            },
            // -------------- ROW 10 -------------- //
            // ------------- histogram ------------ //
            {
              id: "acnu-row-10",
              cells: [
                {
                  id: "acnu-cell-cholesterol-histogram",
                },
              ],
            },
            // -------------- ROW 11 -------------- //
            // ----------- device usage ----------- //
            {
              id: "acnu-row-11",
              cells: [
                {
                  id: "acnu-cell-device-usage-spline",
                },
              ],
            },
            // -------------- ROW 12 -------------- //
            // ------ before after dumbbell ------- //
            {
              id: "acnu-row-12",
              cells: [
                {
                  id: "acnu-cell-before-after-dumbbell-direction",
                },
              ],
            },
            // -------------- ROW 13 -------------- //
            // ------- conversion retention ------- //
            {
              id: "acnu-row-13",
              cells: [
                {
                  id: "acnu-conversion-retention-waterfall",
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        cell: "acnu-cell-sunburst",
        type: "Highcharts",
        chartOptions: getSunburstOptions(acnuData[17], theme),
      },
      {
        cell: "acnu-cell-heat-map",
        type: "Highcharts",
        chartOptions: getHeatmapOptions(acnuData[18], theme),
      },
      {
        cell: "acnu-cell-screen-time",
        type: "Highcharts",
        chartOptions: getScreenTimeOptions(acnuData[0], theme),
      },
      {
        cell: "acun-cell-kickout-zone",
        type: "Highcharts",
        chartOptions: getBarOptions(acnuData[1], theme),
      },
      {
        cell: "acnu-cell-pregnant",
        type: "Highcharts",
        chartOptions: getYesNoOptions(acnuData[2]),
      },
      {
        cell: "acnu-cell-smoking",
        type: "Highcharts",
        chartOptions: getYesNoOptions(acnuData[3]),
      },
      {
        cell: "acnu-cell-alcohol",
        type: "Highcharts",
        chartOptions: getYesNoOptions(acnuData[4]),
      },
      {
        cell: "acnu-cell-cardiac",
        type: "Highcharts",
        chartOptions: getMultiSelectOptions(acnuData[5], theme),
      },
      {
        cell: "acnu-cell-total-cholesterol",
        type: "Highcharts",
        chartOptions: getBlankInputOptions(acnuData[6], theme),
      },
      {
        cell: "acnu-cell-kickout-funnel",
        type: "Highcharts",
        chartOptions: getFunnelOptions(acnuData[7], theme),
      },
      {
        cell: "acnu-cell-age-range",
        type: "Highcharts",
        chartOptions: getColumnRangeOptions(acnuData[8], theme),
      },
      {
        cell: "acnu-cell-reorder",
        type: "Highcharts",
        chartOptions: getLineOptions(acnuData[9], theme),
      },
      {
        cell: "acnu-cell-other-statin",
        type: "Highcharts",
        chartOptions: getVennOptions(acnuData[10], theme),
      },
      {
        cell: "acnu-cell-age-scatter",
        type: "Highcharts",
        chartOptions: getJitterOptions(acnuData[11], theme),
      },
      {
        cell: "acnu-cell-beeswarm",
        type: "Highcharts",
        chartOptions: getBeesWarmOptions(acnuData[12], theme),
      },
      {
        cell: "acnu-cell-cholesterol-histogram",
        type: "Highcharts",
        chartOptions: getHistoOptions(acnuData[13], theme),
      },
      {
        cell: "acnu-cell-device-usage-spline",
        type: "Highcharts",
        chartOptions: getSplineOptions(acnuData[14], theme),
      },
      {
        cell: "acnu-cell-before-after-dumbbell-direction",
        type: "Highcharts",
        chartOptions: getDumbbellDirectionOptions(acnuData[15], theme),
      },
      {
        cell: "acnu-conversion-retention-waterfall",
        type: "Highcharts",
        chartOptions: getWaterfallOptions(acnuData[16], theme),
      },
    ],
  };

  // Update chart options on theme change
  useEffect(() => {
    // Set global chart options
    getChartColors(theme);
  }, [theme]);

  useEffect(() => {
    // if no uuid, redirect to companies page
    if (!uuid) {
      navigate("/companies");
      console.error("Redirecting due to missing company or project");
      return;
    }
  }, [uuid, navigate]);

  if(loading) {
    return <div className="flex flex-col justify-center items-center">
      loading...
    </div>
  }

  return (
    <section className="acnu-dashboard-page page-base">
      <div>
        <Breadcrumbs project={project} currentPage="ACNU Dashboard" />

        <h1 className="text-3xl inline font-semibold grad">{project && project.name}</h1>
      </div>

      <ChartDashboard config={config} id="acnu-dashboard" async={true} />
    </section>
  );
};

export default ACNUDashboard;

/* CHART STYLE FUNCTIONS
  these functions are used to set chart 
  colors, text colors based on the theme
========================================== */
function getTextColor(theme, inverted = false) {
  // if inverted, return black if dark theme, white if light theme
  // useful for text strokes and exceptions
  if (inverted) {
    return theme === "dark" ? "#000000" : "#FFFFFF";
  }
  
  return theme === "dark" ? "#FFFFFF" : "#000000";
}

function getChartColors(theme) {
  const colors = {
    dark: ["#9867FE", "#3a9397", "#FC9ABB", "#FAC093", "#FBDEA5", "#C7D8C5", "#dcfcbd", "#e7594b", "#f3b876"],
    // light: ["#FC9ABB", "#FAC093", "#9867FE", "#4AC1C0", "#FBDEA5"],
    light: ["#FA3F00", "#FF7A00", "#B177FD", "#030000", "#FC794D", "#FFA24D", "#C8A0FE", "#666666", "#F3B876"],
  };

  Highcharts.setOptions({
    colors: colors[theme] || colors.dark,
  });

  return colors[theme] || colors.dark;
}

function getGridLineColors(theme) {
  return theme === "dark" ? "#707073" : "#e6e6e6";
}

/* CHART OPTIONS FUNCTIONS
   this is where api data gets combined with chart options
========================================== */
// returns the options for a yes/no chart
function getYesNoOptions(data) {
  const { id, title, subtitle, seriesKey, series } = data;
  return {
    id: id,
    chart: {
      type: "pie",
    },
    title: {
      text: title,
      align: "left",
    },
    subtitle: {
      text: subtitle,
      align: "left",
    },
    tooltip: {
      valueSuffix: "",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          distance: -50,
          format: "<b>{point.name}</b>: {point.percentage:.1f}%",
        },
      },
    },
    series: [
      {
        name: seriesKey,
        data: series.map((d, i) => {
          return {
            name: d.name,
            y: d.y,
          };
        }),
      },
    ],
  };
}

// returns the options for a multiselect chart
function getMultiSelectOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, series } = data;
  return {
    id: id,
    chart: {
      type: "column",
    },
    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    xAxis: {
      categories: xAxis.categories,
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },
    yAxis: {
      min: yAxis.min || 0,
      title: {
        text: yAxis.title.text || "",
        style: {
          color: getTextColor(theme),
        },
      },
      gridLineColor: getGridLineColors(theme),
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "white",
        textOutline: "1px solid #000000",
      },
      itemHoverStyle: {
        color: Highcharts.getOptions().colors[0],
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}; font-weight: bold;">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          format: "{point.y:.0f}",
        },
      },
    },
    series: series.map((s, i) => {
      return {
        name: s.name,
        data: s.data,
        color: Highcharts.getOptions().colors[i],
      };
    }),
  };
}

// returns the options for a blank input chart
function getBlankInputOptions(data, theme = "dark") {
  const { id, title, subtitle, xAxis, yAxis, series } = data;
  return {
    id: id,
    chart: {
      type: "boxplot",
    },
    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    xAxis: {
      categories: xAxis.categories,
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },
    yAxis: {
      min: yAxis.min || 0,
      title: {
        text: yAxis.title.text || "",
        style: {
          color: getTextColor(theme),
        },
      },
      gridLineColor: getGridLineColors(theme),
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "white",
        textOutline: "1px solid #000000",
      },
      itemHoverStyle: {
        color: Highcharts.getOptions().colors[0],
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}; font-weight: bold;">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          format: "{point.y:.0f}",
        },
      },
    },
    series: [
      {
        type: "boxplot",
        name: series[0].name || "",
        fillColor: "rgba(255, 255, 255, 0.75)",
        data: series[0].data || [],
        tooltip: {
          headerFormat: "<em>{point.key}</em><br/>",
        },
      },
      {
        type: "scatter",
        name: series[1].name || "",
        color: Highcharts.getOptions().colors[0],
        data: series[1].data || [],
        marker: {
          fillColor: "white",
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[0],
        },
        tooltip: {
          pointFormat: "{point.y}",
        },
      },
      {
        type: "scatter",
        name: series[2].name || "",
        color: Highcharts.getOptions().colors[1],
        data: series[2].data || [],
        marker: {
          fillColor: Highcharts.getOptions().colors[5],
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[5],
        },
        tooltip: {
          headerFormat: "<em>{point.key} Avg</em><br/>",
          pointFormat: "{point.y}",
        },
      },
    ],
  };
}

// returns the options for a screen time chart
function getScreenTimeOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, series } = data;
  return {
    id: id,
    chart: {
      type: "column",
      styledMode: false,
    },
    title: {
      text: title,
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    subtitle: {
      text: subtitle,
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: getTextColor(theme),
        // textOutline: "1px solid #000000",
      },
      itemHoverStyle: {
        color: Highcharts.getOptions().colors[0],
      },
    },
    labels: {
      style: {
        color: getTextColor(theme),
      },
    },
    xAxis: {
      categories: xAxis.categories,
      accessibility: xAxis.accessibility,
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },
    yAxis: {
      min: yAxis.min || 0,
      title: {
        text: yAxis.title.text || "",
        style: {
          color: getTextColor(theme),
        },
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
    },
    tooltip: {
      valueSuffix: "s",
      style: {
        color: getTextColor(theme),
      },
    },
    plotOptions: {
      series: {
        pointPadding: 0.2,
        borderWidth: 1,
      },
    },
    series: [
      {
        type: "column",
        name: series[0].name,
        data: series[0].data,
        borderWidth: 1,
        borderColor: "#FF0000",
      },
      {
        type: "errorbar",
        name: series[1].name,
        data: series[1].data,
        color: getTextColor(theme),
      },
    ],
  };
}

// returns the options for a column range chart
function getColumnRangeOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, series } = data;

  return {
    chart: {
      id: id,
      type: "columnrange",
      inverted: false,
    },

    title: {
      text: title.text,
      style: {
        color: getTextColor(theme),
      },
      align: "left",
    },

    subtitle: {
      text: subtitle.text,
      style: {
        color: getTextColor(theme),
      },
      align: "left",
    },

    xAxis: {
      title: {
        text: xAxis.title.text,
        style: {
          color: getTextColor(theme),
        },
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      categories: xAxis.categories,
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },

    yAxis: {
      title: {
        text: yAxis.title.text,
        style: {
          color: getTextColor(theme),
        },
        labels: {
          style: {
            color: getTextColor(theme),
          },
        },
      },
    },

    tooltip: {
      valueSuffix: " ",
    },

    plotOptions: {
      columnrange: {
        borderRadius: "50%",
        dataLabels: {
          enabled: true,
          format: "{y}",
        },
        color: Highcharts.getOptions().colors[0],
      },
    },

    legend: {
      enabled: false,
    },

    series: series,
  };
}

// returns the options for a bar chart
function getBarOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, series } = data;
  return {
    chart: {
      id: id,
      type: "bar",
    },

    title: {
      text: title || "",
      align: "left",
    },
    subtitle: {
      text: subtitle || "",
      align: "left",
    },

    xAxis: {
      title: {
        text: xAxis.title,
      },
      categories: xAxis.categories,
    },

    yAxis: {
      title: {
        text: yAxis.title.text,
      },
    },

    tooltip: {
      valueSuffix: " ",
    },

    plotOptions: {
      bar: {
        borderRadius: "50%",
        dataLabels: {
          enabled: true,
        },
        groupPadding: 0.1,
      },
    },

    legend: {
      enabled: false,
    },

    series: series,
  };
}

// returns the options for a line chart
function getLineOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, series } = data;
  return {
    chart: {
      id: id,
      type: "line",
    },

    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    xAxis: {
      title: {
        text: xAxis.title.text,
        style: {
          color: getTextColor(theme),
        },
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      categories: xAxis.categories,
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },

    yAxis: {
      title: {
        text: yAxis.title.text,
        style: {
          color: getTextColor(theme),
        },
        labels: {
          style: {
            color: getTextColor(theme),
          },
        },
      },
    },

    tooltip: {
      valueSuffix: " ",
    },

    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
        color: Highcharts.getOptions().colors[0],
      },
    },

    legend: {
      enabled: false,
    },

    series: series,
  };
}

// returns the options for a venn chart
function getVennOptions(data, theme) {
  const { id, title, subtitle, series } = data;
  return {
    chart: {
      id: id,
      type: "venn",
    },

    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    accessibility: {
      point: {
        descriptionFormat:
          "{add index 1}. Intersection: {sets}. " +
          "{#if (gt sets.length 1)}{name}. {/if}" +
          "Value {value}",
      },
    },

    tooltip: {
      valueSuffix: " ",
    },

    series: [
      {
        type: "venn",
        data: series.map((d, i) => {
          return {
            sets: d.sets,
            value: d.value,
            color: Highcharts.getOptions().colors[i],
          };
        }),
      },
    ],
  };
}

// returns the options for a funnel chart
function getFunnelOptions(data, theme) {
  const { id, title, subtitle, series } = data;
  return {
    chart: {
      id: id,
      type: "funnel",
    },

    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b> ({point.y:,.0f})",
          softConnector: true,
        },
        center: ["40%", "50%"],
        neckWidth: "30%",
        neckHeight: "25%",
        width: "80%",
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            plotOptions: {
              series: {
                dataLabels: {
                  inside: true,
                },
                center: ["50%", "50%"],
                width: "100%",
              },
            },
          },
        },
      ],
    },
    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    tooltip: {
      valueSuffix: " ",
    },

    series: [
      {
        data: series.map((d, i) => {
          return {
            name: d.name,
            y: d.y,
            color: Highcharts.getOptions().colors[i],
          };
        }),
      },
    ],
  };
}

// returns the options for a jitter plot chart
function getJitterOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, series } = data;
  return {
    chart: {
      id: id,
      type: "scatter",
      styledMode: false,
    },

    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    xAxis: {
      title: {
        text: xAxis.title.text,
        style: {
          color: getTextColor(theme),
        },
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      categories: xAxis.categories,
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },

    yAxis: {
      title: {
        text: yAxis.title.text,
        style: {
          color: getTextColor(theme),
        },
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },

      plotLines: [
        {
          value: yAxis.plot_width2,
          color: Highcharts.getOptions().colors[1],
          width: 6,
        },
        {
          value: yAxis.plot_width,
          color: Highcharts.getOptions().colors[1],
          width: 6,
        },
      ],
      plotBands: {
        from: yAxis.plot_width,
        to: yAxis.plot_width2,
        color: Highcharts.getOptions().colors[2],
        label: {
          // text: yAxis.plotBands.label.text,
          text: "Qualification Range",
          style: { color: getTextColor(theme) },
          verticalAlign: "top",
          align: "left",
          x: 10 /*adjust how close the text to the border, horizontally*/,
          y: 20 /*adjust how close the text to the border, vertically*/,
          zIndex: 997,
        },
      },
    },

    tooltip: {
      valueSuffix: " ",
      style: {
        color: getTextColor(theme),
      },
    },

    plotOptions: {
      scatter: {
        showInLegend: false,
        jitter: {
          x: 0.24,
          y: 0,
        },
        marker: {
          radius: 2,
          symbol: "circle",
        },
        tooltip: {
          pointFormat: "Measurement: {point.y:.3f}",
        },
        zIndex: 1,
      },
    },

    legend: {
      enabled: false,
    },

    series: series,
  };
}

// returns the options for a jitter plot chart, shapley values
function getBeesWarmOptions(data, theme) {
  const { id, title, xAxis, yAxis, series } = data;
  return {
    chart: {
      id: id,
      type: "scatter",
      inverted: true,
    },

    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    xAxis: {
      title: {
        text: xAxis.title.text,
        style: {
          color: getTextColor(theme),
        },
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      categories: xAxis.categories,
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },

    yAxis: {
      title: {
        text: "shapley values",
        style: {
          color: getTextColor(theme),
        },
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },

      plotLines: [
        {
          value: 0.0,
          color: Highcharts.getOptions().colors[1],
          width: 3,
        },
      ],
    },

    tooltip: {
      valueSuffix: " ",
    },

    plotOptions: {
      scatter: {
        showInLegend: false,
        jitter: {
          x: 0.24,
          y: 0,
        },
        marker: {
          radius: 2,
          symbol: "circle",
        },
        color: Highcharts.getOptions().colors[0],
        tooltip: {
          pointFormat: "Measurement: {point.y:.3f}",
        },
        zIndex: 1,
      },
    },

    legend: {
      enabled: false,
    },

    series: series,
  };
}

// returns the options for histogram with scatter plot
function getHistoOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, series } = data;
  return {
    chart: {
      id: id,
      styledMode: false,
    },

    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    legend: {
      itemStyle: {
        color: getTextColor(theme),
      },
    },

    xAxis: [
      {
        title: {
          text: "Data",
          style: {
            color: getTextColor(theme),
          },
        },

        labels: {
          style: {
            color: getTextColor(theme),
          },
        },
        categories: xAxis.categories,
        lineColor: getGridLineColors(theme),
        tickColor: getGridLineColors(theme),

        alignTicks: false,
      },
      {
        title: {
          text: "Histogram",
          style: {
            color: getTextColor(theme),
          },
        },
        labels: {
          style: {
            color: getTextColor(theme),
          },
        },
        categories: xAxis.categories,
        lineColor: getGridLineColors(theme),
        tickColor: getGridLineColors(theme),

        alignTicks: false,
        opposite: true /*the second axis is on the other side*/,
      },
    ],
    yAxis: [
      {
        title: {
          text: "Data",
          style: {
            color: getTextColor(theme),
          },
        },
        labels: {
          style: {
            color: getTextColor(theme),
          },
        },
      },
      {
        title: {
          text: "Frequency",
          style: {
            color: getTextColor(theme),
          },
        },
        labels: {
          style: {
            color: getTextColor(theme),
          },
        },
        opposite: true,
      },
    ],

    tooltip: {
      valueSuffix: " ",
      style: {
        color: getTextColor(theme),
      },
    },

    plotOptions: {
      histogram: {
        color: Highcharts.getOptions().colors[0],
        borderColor: getTextColor(theme),
        accessibility: {
          point: {
            valueDescriptionFormat:
              "{index}. {point.x:.1f} to {point.x2:.1f}, {point.y}.",
          },
        },
      },

      scatter: {
        color: Highcharts.getOptions().colors[1],
        marker: {
          radius: 3,
          symbol: "circle",
          lineColor: getTextColor(theme),
        },
      },
    },

    series: series,
  };
}

// returns the options for a spline chart
function getSplineOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, series } = data;
  return {
    chart: {
      id: id,
      type: "spline",
    },

    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    xAxis: {
      title: {
        text: xAxis.title.text,
        style: {
          color: getTextColor(theme),
        },
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      categories: xAxis.categories,
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },

    yAxis: {
      title: {
        text: yAxis.title.text,
        style: {
          color: getTextColor(theme),
        },
        labels: {
          style: {
            color: getTextColor(theme),
          },
        },
      },
    },

    tooltip: {
      valueSuffix: "%",
      stickOnContact: true,
    },

    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {
              window.location.href = this.series.options.website;
            },
          },
        },
        cursor: "pointer",
        lineWidth: 2,
      },
    },

    legend: {
      enabled: true,
      itemWidth: 150,
      itemStyle: {
        color: getTextColor(theme),
      },
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 550,
          },
          chartOptions: {
            chart: {
              spacingLeft: 3,
              spacingRight: 3,
            },
            legend: {
              itemWidth: 150,
            },

            yAxis: {
              visible: false,
            },
          },
        },
      ],
    },

    series: series.map((s, i) => {
      return {
        name: s.name,
        data: s.data,
        dashStyle: s.dashStyle,
        color: Highcharts.getOptions().colors[i],
      };
    }),
  };
}

// returns the options for a dumbbell chart with direction
function getDumbbellDirectionOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, seriesm, tooltip, series } = data;
  return {
    chart: {
      id: id,
      type: "dumbbell",
      inverted: true,
    },

    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    xAxis: {
      type: "category",
      opposite: true,
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },

    yAxis: {
      title: " ",
    },

    tooltip: {
      pointFormat: tooltip.pointFormat,
      shared: true,
    },

    legend: {
      enabled: false,
    },

    plotOptions: {
      series: {
        connectorWidth: 3,
        marker: {
          radius: 5,
          states: {
            hover: {
              lineWidth: 0,
            },
          },
        },
        //   lowMarker: {
        //     radius: 5,
        //     states: {
        //         hover: {
        //             lineWidth: 0
        //         }
        //     }
        // },
        dataLabels: {
          enabled: true,
          color: getTextColor(theme),
          crop: false,
          overflow: "allow",
        },
      },
    },

    series: series.map((s, i) => {
      return {
        name: s.name,
        data: s.data,
        color: Highcharts.getOptions().colors[i],
        lowMarker: s.lowMarker,
        marker: s.marker,
      };
    }),
  };
}

// returns the options for a waterfall chart, retention and conversion
function getWaterfallOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, series } = data;
  return {
    chart: {
      id: id,
      type: "waterfall",
      styledMode: false,
    },

    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },
    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    xAxis: {
      type: "category",
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },

    yAxis: {
      title: {
        text: yAxis.title.text || "",
        style: {
          color: getTextColor(theme),
        },
      },
      gridLineColor: getGridLineColors(theme),
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
    },

    legend: {
      enabled: false,
    },

    tooltip: {
      pointFormat: "<b>{point.y:,.0f}</b> Assessments",
      style: {
        color: getTextColor(theme),
      },
    },

    series: [
      {
        upColor: Highcharts.getOptions().colors[0],
        color: Highcharts.getOptions().colors[1],
        data: series[0].data,
        dataLabels: {
          enabled: true,
          format: "{divide y 1000}k",
          style: {
            color: getTextColor(theme),
          },
        },
        pointPadding: 0,
      },
    ],
  };
}

// returns the options for a blank input chart
function getSunburstOptions(data, theme) {
  const { id, type, title, subtitle, series } = data;
  return {
    chart: {
      id: id,
      type: type,
      styledMode: false,
      height: 650
    },

    title: {
      text: title || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    subtitle: {
      text: subtitle || "",
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    tooltip: {
      style: {
        color: getTextColor(theme),
      },
    },

    drilldown: {
      breadcrumbs: {
        buttonTheme: {
          fill: '#f7f7f7',
          padding: 8,
          stroke: '#cccccc',
          'stroke-width': 1,
          style: {
            color: '#ff0000'
          }
      },
      }
    },

    series: [
      {
        type: "sunburst",
        data: series,
        name: "Root",
        color: ["transparent"].concat(Highcharts.getOptions().colors[0]), // let the center circle be transparent
        allowDrillToNode: true,
        borderRadius: 3,
        cursor: "pointer",
        dataLabels: {
          format: "{point.name}",
          filter: {
            property: "innerArcLength",
            operator: ">",
            value: 16,
          },
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: {
                property: "outerArcLength",
                operator: ">",
                value: 64,
              },
            },
          },
          {
            level: 2,
            colorByPoint: true,
          },
          {
            level: 3,
            colorVariation: {
              key: "brightness",
              to: -0.1,
            },
          },
          {
            level: 4,
            colorVariation: {
              key: "brightness",
              to: 0.5,
            },
          },
        ],
      },
    ],
  };
}

// returns the options for a heatmap chart
function getHeatmapOptions(data, theme) {
  const { id, title, subtitle, xAxis, yAxis, series } = data;
  return {
    id: id,
    chart: {
      type: "heatmap",
      marginTop: 60,
      marginBottom: 60,
      plotBorderWidth: 1,
      // styledMode: false,
    },

    title: {
      text: title,
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    subtitle: {
      text: subtitle,
      align: "left",
      style: {
        color: getTextColor(theme),
      },
    },

    xAxis: {
      categories: xAxis.categories,
      style: {
        color: getTextColor(theme),
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
    },

    yAxis: {
      categories: yAxis.categories,
      title: null,
      reversed: true,
      style: {
        color: getTextColor(theme),
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
    },

    colorAxis: {
      min: -1,
      minColor: "#FFFFFF",
      maxColor: getChartColors(theme)[0],
    },

    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 40,
      symbolHeight: 280,
    },

    series: [
      {
        name: series.name,
        borderWidth: 1,
        data: series[0].data || [],
        dataLabels: {
          enabled: true,
          style: {
            color: getTextColor(theme),
          }
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {
                format: "{substr value 0 1}",
              },
            },
          },
        },
      ],
    },
  };
}
