import Markdown from "react-markdown";

import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import './SearchOpportunity.css';

function SearchOpportunity({ universe, condition_analysis }) {
  if ( !universe || Object.keys(universe).length === 0 ||
    !universe['Grand Total'] || universe['Grand Total'].search_volume_avg_annual_total === 0
    || !condition_analysis || Object.keys(condition_analysis).length === 0
    ) {
    return <p className="min-h-[550px] px-4 py-8 font-bold grid place-content-center">Search Spend Rec data is not available.</p>
  }

return <div className="search-opportunity">
  <div className="content">
    <div className="col col-1">
      <h4>Summary</h4>

      <div className="space-y-4 p-4">
      <p>
        There were ~{ universe['Grand Total'].search_volume_avg_annual_total.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } searches performed in the past 12 months.
      </p>

      {
        condition_analysis.untreated_vs_treated_chartinfo.description 
        && <Markdown>{ condition_analysis.untreated_vs_treated_chartinfo.description }</Markdown>
      }
      </div>
    </div>
    <div className="col col-2">
      <ChartDashboard config={chartOptions(universe)} id="search-opportunity-container" />
    </div>
  </div>
</div>
} 

export default SearchOpportunity;

const chartOptions = (data) => {
  const totalSearchVolume = data['Grand Total'].search_volume_avg_annual_total;
  const brandPercentage = (data['Brand Subtotal'].search_volume_avg_annual_total / totalSearchVolume) * 100;
  const conditionPercentage = (data['Condition Subtotal'].search_volume_avg_annual_total / totalSearchVolume) * 100;
  const searchVolumeMonths = data['Grand Total'].search_volume_months


  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `search-opportunity-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `search-opportunity-cell`,
        chartOptions: {
          chart: {
            height: 536,
            styledMode: false,
          },
          title: {
            text: 'Search Interest for "High Cholesterol" in U.S.',
            align: 'left',

          },
          xAxis: {
            categories: searchVolumeMonths
          },
          yAxis: {
            title: {
              text: '',
            },
            labels: {
            },
            min: 0,
          },
          legend: {
            enabled: false
          },
          series: [{
            type: 'line',
            name: 'Search Interest',
            data: data['Grand Total'].search_volume_monthly_totals,
          }, {
            type: 'pie',
            name: null,
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              headerFormat: '',
              pointFormatter: function() {
                const colors = Highcharts.getOptions().colors;
                const colorIndex = this.colorIndex || 0;
                const color = colors[colorIndex];
                return `<span style="color:${color}">●</span> ${this.name}: <b>${this.percentage.toFixed(1)}%</b>`;
              }
            },
            data: [
              { 
                name: 'Brand', 
                y: brandPercentage,
              },
              { 
                name: 'Condition', 
                y: conditionPercentage,
              }
            ],
            center: ['90%', 310],
            size: 150,
            innerSize: '70%',
            showInLegend: false,
            legend: {
              enabled: true
            },
          }]
        }
      },
    ],
  };
};
