import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons"

import { $axios_old as axios } from "../../services/axiosInstance";

import ToolTipLabel from "../../components/ToolTipLabel/ToolTipLabel";

const OpenAiForm = ({ prompt, setPrompt, setResponse, setLoading, isDictating, setIsDictating }) => {
// openai default settings
// "model": "gpt-4",
// "prompt": prompt,
// "temperature": 1,
// "max_tokens": 1000,
// "top_p": 1,
// "frequency_penalty": 1,
// "presence_penalty": 1

  const [formData, setFormData] = useState({
    prompt: "",
    model: "gpt-4", // default model
    temperature: 1,
    max_tokens: 256,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  });

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSetPrompt = (event) => {
    setPrompt(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setResponse('Working on that...');

    formData.max_tokens = +formData.max_tokens;
    formData.temperature = +formData.temperature;
    formData.top_p = +formData.top_p;
    formData.frequency_penalty = +formData.frequency_penalty;
    formData.presence_penalty = +formData.presence_penalty;

    formData.prompt = prompt;

    try {
      const { data } = await axios.post("/api/genai/openai", formData);

      setResponse(data.response.choices[0].message.content);

    } catch (error) {
      setResponse("Something went wrong. Please try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleDictation = () => {
    setIsDictating(!isDictating);
  };

  return (
    <form onSubmit={handleSubmit}>
    <div className="form-group">
      {/* select options based on the currently selectd model */}
      <label className="text-sm" htmlFor="model">Model</label>
      <select name="model" id="model">
        <option value="gpt-4">GPT-4</option>
        <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
      </select>
    </div>

    <div className="form-group">
      <ToolTipLabel 
        labelText="Temperature"
        htmlFor={"temperature"}
        toolTipText={"Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive. Higher temperature results in more random completions."}
        elValue={formData.temperature}
      />
      <input
        type="range"
        name="temperature"
        id="temperature"
        data-testid="temperature-input"
        min="0"
        max="2"
        step="0.01"
        value={formData.temperature} 
        onChange={handleInputChange} 
      />
    </div>

    <div className="form-group">
      <ToolTipLabel
        labelText="Token Limit"
        htmlFor={"max_tokens"}
        toolTipText={"Controls the number of tokens to generate. Very few tokens (e.g. 16) may result in a 'hallucination' effect, where the model just repeats its prompt. Very large tokens (e.g. 1024) may run into memory issues."}
        elValue={formData.max_tokens}
      />
      <input
        type="range"
        name="max_tokens"
        id="max_tokens"
        min="1"
        max="4000"
        step="21"
        value={formData.max_tokens} 
        onChange={handleInputChange} 
      />
    </div>

    <div className="form-group">
      <ToolTipLabel
        labelText="Top-P"
        htmlFor={"top_p"}
        toolTipText={"Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered."}
        elValue={formData.top_p}
      />
      <input
        type="range"
        name="top_p"
        id="top_p"
        min="0"
        max="1"
        step="0.1"
        value={formData.top_p}
        onChange={handleInputChange}
      />
    </div>

    <div className="form-group">
      <ToolTipLabel
        labelText="Frequency Penalty"
        htmlFor={"frequency_penalty"}
        toolTipText={"How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim."}
        elValue={formData.frequency_penalty}
      />
      <input
        type="range"
        name="frequency_penalty"
        id="output-threshold"
        min="0"
        max="2"
        step="0.01"
        value={formData.frequency_penalty}
        onChange={handleInputChange}
      />
    </div>

    <div className="form-group">
      <ToolTipLabel
        labelText="Presence Penalty"
        htmlFor={"presence_penalty"}
        toolTipText={"How much to penalize new tokens based on whether they appear in the text so far. Decreases the model's likelihood to talk about the topic of the text so far."}
        elValue={formData.presence_penalty}
      />
      <input
        type="range"
        name="presence_penalty"
        id="output-probability"
        min="0"
        max="2"
        step="0.01"
        value={formData.presence_penalty}
        onChange={handleInputChange}
      />
    </div>

    <div className="form-group prompt">
      <label className="text-sm" htmlFor="prompt">
        Prompt
      </label>
      <textarea
        name="prompt"
        id="prompt"
        cols="30"
        rows="10"
        placeholder="Write a prompt and then click submit. To summarize text from a web page paste link and click summarize"
        value={prompt}
        onChange={handleSetPrompt}
      ></textarea>
      <button className="dictation-btn" aria-label="start/stop dictation" onClick={handleDictation} type="button">
        <FontAwesomeIcon icon={faMicrophone} size="sm" />
      </button>
    </div>

    <div className="form-group button-group">
      <button type="submit" className="btn">Submit</button>
      <button type="button" className="btn">Upload Text</button>
      <button type="button" className="btn">Summarize</button>

    </div>
  </form>
  );
};

export default OpenAiForm;
