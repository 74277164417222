import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";
import { formatMonths } from "../../../utils/chartUtils.js";

import "./SearchVolMonthType.css";

// data: project.keyword_universe - Brand Subtotal, Condition Subtotal
// Search Volume by Month by Type
const SearchVolMonthType = ({ data }) => {

 // if data does not include a proprty called 'Brand Subtotal' or 'Condition Subtotal' return a message
  if(!data['Brand Subtotal'] || !data['Condition Subtotal']) {
    return <p className="px-4 py-8 font-bold text-center">Search Volume by Month by Type is not available.</p>
  }

  return <ChartDashboard config={chartOptions(data)} async={true} id="consumer-journey" />;
};

export default SearchVolMonthType;

const chartOptions = (data) => {
  const { 
    search_volume_monthly_totals: brandSubtotal, 
    search_volume_months: brandMonths 
  } = data['Brand Subtotal'] || {};
  const { 
    search_volume_monthly_totals: conditionSubtotal, 
    search_volume_months: conditionMonths 
  } = data['Condition Subtotal'] || {};


  const brandData = brandSubtotal.map((value, index) => [brandMonths[index], value]);
  const conditionData = conditionSubtotal.map((value, index) => [conditionMonths[index], value]);

  return {
      gui: {
        layouts: [
          {
            rows: [
              {
                cells: [
                  {
                    id: "consumer-journey-cell",
                  },
                ],
              },
            ],
          },
        ],
      },
      components: [
        {
          type: "Highcharts",
          cell: "consumer-journey-cell",
          chartOptions: {
            chart: {
              type: 'area',
              styledMode: false,
              spacingBottom: 10,
              spacingTop: 10,
              spacingLeft: 10,
              spacingRight: 10,
              height: 550,
            },
            accessibility: {
              description: 'Image description:'
            },
            title: {
              text: 'Search Volume by Month by Type',
            },
            subtitle: {
              text: 'Source: <a href="https://semrush.com" target="_blank">SEMRush</a>'
            },
            xAxis: {
              type: 'datetime',
              accessibility: {
                rangeDescription: 'Range: 12 months.'
              },
              categories: formatMonths(data[Object.keys(data)[0]].search_volume_months),
              gridLineColor: 'rgb(229, 228, 226, 1)',
              gridLineWidth: 1
            },
            yAxis: {
              title: {
                text: 'Total Search Volume',
                style: {
                  fontSize: '18px'
                },
                margin: 16
              },
              gridLineColor: 'rgb(229, 228, 226, 1)',
              gridLineWidth: 1
            },
            tooltip: { 
              useHTML: true,
              shadow: false,
              borderWidth: 2,
              headerFormat: '<table>',
              pointFormat:  `
              <tr><th>Month:</th><td>{point.category}</td></tr>
              <tr><th>Monthly Volume:</th><td>{point.y}</td></tr>
            `,
              footerFormat: '</table>'
            },
            plotOptions: {
              area: {
                marker: {
                  enabled: false,
                  symbol: 'circle',
                  radius: 8,
                  states: {
                    hover: {
                      enabled: true
                    }
                  }
                }
              }
            },
            legend: {
              align: 'center',
              verticalAlign: 'top',
              layout: 'horizontal',
              backgroundColor: 'rgb(248,248,248)',
              borderWidth: '1',
              borderColor: 'rgb(230,230,230)'
            },
            series: [{
              name: 'Condition Search Volume',
              data: conditionData,
              color: {
                  linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                  },
                  stops: [
                    [0, 'rgb(34, 0, 112)'],
                  ]
                }
            },
            {
              name: 'Branded Search Volume',
              data: brandData,
              color: {
                  linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                  },
                  stops: [
                    [0, 'rgb(255, 20, 145, 1)'],
                    [.999, 'rgb(255, 114, 71, 1)']
                  ]
                }
            }]
        }
        },
      ],
  }
}