import { useState, useEffect, useRef } from 'react';
import { $axios } from '../services/axiosInstance';

const useStockData = (ticker) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const wsRef = useRef(null); // Using a ref to persist WebSocket instance across renders
  const dataRef = useRef(data); // Create a reference for data

  // Update the data reference when the data state changes
  useEffect(() => {
    dataRef.current = data; // Update the reference value whenever data changes
  }, [data]);

  useEffect(() => {
    setError(null);

    const fetchStockData = async () => {
      try {
        const response = await $axios.get(
          `/historical_polygon_data/${ticker}/`
        );

        setData(response.data.results);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const initWebSocket = () => {
      wsRef.current = new WebSocket("ws://localhost:3002");
      wsRef.current.onopen = () => {
        console.log("Connected to the WebSocket server");
  
        // Send the stock ticker to the server once connected
        wsRef.current.send(ticker);
      };
  
      wsRef.current.onmessage = function (event) {
        const handleData = (result) => {
          if (result.status === "ready_for_subscription") {
            // Send the subscription ticker to the server
            if (wsRef.current.readyState === WebSocket.OPEN) {
              wsRef.current.send(ticker);
            }
          } else if (result.ev === "A") {
            if(dataRef.current.length === 0) return;
  
            const lastCandleTimestamp = dataRef.current[dataRef.current.length - 1].t;
            const nextCandleStartTime = lastCandleTimestamp + 86400000; // Add 24 hours
          
            if (result.e >= nextCandleStartTime) {
              // Create a new candle
              setData((prevData) => [...prevData, {
                t: result.e, 
                o: result.o, 
                h: result.h, 
                l: result.l, 
                c: result.c, 
                v: result.v, 
                vw: result.vw,
              }]);
            } else {
              // Update the current candle
              setData((prevData) => {
                const updatedData = prevData.map(candle => {
                  if (candle.t === lastCandleTimestamp) {
                    return {
                      ...candle,
                      h: Math.max(candle.h, result.h),
                      l: Math.min(candle.l, result.l),
                      c: result.c, 
                      v: candle.v + result.v, 
                      vw: (candle.vw * candle.v + result.vw * result.v) / (candle.v + result.v), // Update VWAP
                    };
                  }
                  return candle;
                });
                return updatedData;
              });
            }
          }
        };
  
        if (event.data instanceof Blob) {
          // If the data is a Blob, convert it to a string
          const reader = new FileReader();
          reader.onload = function () {
            const rawData = JSON.parse(reader.result);
            const result =
              Array.isArray(rawData) && rawData.length ? rawData[0] : rawData;
  
            handleData(result);
          };
          reader.readAsText(event.data);
        } else {
          const rawData = JSON.parse(event.data);
          const result =
            Array.isArray(rawData) && rawData.length ? rawData[0] : rawData;
          handleData(result);
        }
      };
  
      wsRef.current.onerror = (wsError) => {
        console.error("WebSocket Error:", wsError);
        // Handle the error as required
      };
    }

    if(!ticker) {
      setError("Company ticker is not available.");
      return;
    }

    fetchStockData();
    initWebSocket();

    return () => {
      console.log("Attempting to close the WebSocket connection");
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        console.log("Closing the WebSocket connection for real");
        wsRef.current.close();
      }
    };
  }, [ticker]);

  return { data, loading, error };
};

export default useStockData;