import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";

import { useTheme } from "../../utils/ThemeManager.jsx";
import useStockData from "../../hooks/useStockData.js";

import { getTextColor, getGridLineColors } from "../../utils/chartUtils.js"; 

import "./CompanyChart.css";

highchartsAccessibility(Highcharts);

export const formatLargeNumber = (largeNumber) => {
  if (typeof largeNumber !== 'number' || isNaN(largeNumber)) {
    return 'Invalid input'; // or any other appropriate response
  }

  if (largeNumber === 0) {
    return '$0.00';
  }

  const units = ["", "K", "M", "B", "T"];
  const index = Math.floor(Math.log10(largeNumber) / 3);
  const newValue = largeNumber / 1000 ** index;
  return `${newValue.toFixed(2)}${units[index]}`;
};

export const StockInfo = ({ ticker, chartData }) => {
  const [initialPrice, setInitialPrice] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const priceChange = (currentPrice - initialPrice).toFixed(2);
  const percentageChange = (
    ((currentPrice - initialPrice) / initialPrice) *
    100
  ).toFixed(2);
  const changeClass = parseFloat(priceChange) > 0 ? "positive" : "negative";

  useEffect(() => {
    if (chartData.length > 0) {
      setInitialPrice(chartData[0].c.toFixed(2));
      setCurrentPrice(chartData[chartData.length - 1].c.toFixed(2));
    }
  }, [chartData]);

  return (
    <div className="stock-info">
      <h3 className="ticker">{ticker}</h3>
      <p className="price">$ {currentPrice}</p>
      <div className="historical">
        <p className={`change ${changeClass}`}>
          ${parseFloat(priceChange).toFixed(2)} ({Math.abs(percentageChange)}%)
        </p>
        <span>Past Month</span>
      </div>
    </div>
  );
};

export const StockChart = ({ chartData = [] }) => {
  const chartRef = useRef(null);
  const { theme } = useTheme();

  const [selectedButton, setSelectedButton] = useState(1);

  const formattedData = chartData.map((d) => {
    return [
      d.t, // timestamp
      d.o, // open price
      d.h, // high price
      d.l, // low price
      d.c, // close price
      d.v, // volume
      d.vw, // average volume
    ];
  });

  const chartOptions = {
    attributes: {
      id: "stock-chart-broseph",
    },
    chart: {
      backgroundColor: null,
      reflow: true,
      animation: false,
      styleMode: false,
    },
    title: {
      text: null,
    },
    navigator: {
      enabled: true,
      outlineColor: getTextColor(theme),
    },
    tooltip: {
      style: {
        color: "white",
        stroke: "purple"
      }
    },
    xAxis: {
      events: {
        setExtremes: function (e) {
          if (
            e.trigger === "navigator" ||
            e.trigger === "rangeSelector" ||
            e.trigger === "zoom"
          ) {
            setSelectedButton(null);
          }
        },
      },
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
      lineColor: getGridLineColors(theme),
      tickColor: getGridLineColors(theme),
    },
    yAxis: {
      labels: {
        style: {
          color: getTextColor(theme),
        },
      },
    },

    rangeSelector: {
      selected: selectedButton,
      enabled: true,
      buttonTheme: { // styles for the buttons
        fill: 'none',
        stroke: '#fff',
        'stroke-width': 0,
        r: 0,
        style: {
            color: 'white'
            
        },
        states: {
            hover: {
              fill: '#0F0',
            },
            select: {
                fill: '#023',
                style: {
                    color: 'white',
                    fontWeight: 'bold'
                }
            }
        // disabled: { ... }
        }
    },
      inputBoxBorderColor: 'gray',
      inputBoxWidth: 120,
      inputBoxHeight: 18,
      inputStyle: {
          color: getTextColor(theme),
          fontWeight: 'bold'
      },
      buttons: [
        {
          type: "week",
          count: 1,
          text: "1w",
          events: {
            click: () => setSelectedButton(0),
          },
        },
        {
          type: "month",
          count: 1,
          text: "1m",
          events: {
            click: () => setSelectedButton(1),
          },
        },
        {
          type: "month",
          count: 3,
          text: "3m",
          events: {
            click: () => setSelectedButton(2),
          },
        },
        {
          type: "ytd",
          text: "YTD",
          events: {
            click: () => setSelectedButton(3),
          },
        },
        {
          type: "year",
          text: "1y",
          count: 1,
          events: {
            click: () => setSelectedButton(4),
          },
        },
        {
          type: "all",
          text: "All",
          events: {
            click: () => setSelectedButton(5),
          },
        },
      ],
    },


    scrollbar: {
      enabled: false,
    },
    series: [
      {
        type: "candlestick",
        name: "Stock Price",
        data: formattedData,
      },
    ],
  };

  return (
    <HighchartsReact
      containerProps={{ className: "company-stock-chart" }}
      ref={chartRef}
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={chartOptions}
    />
  );
};

export const StockPerformance = ({ chartData = [], marketCap = 0 }) => {
  // chartData is an array of objects
  // [0]: d.t - date
  // [1]: d.o - open
  // [2]: d.h - high
  // [3]: d.l - low
  // [4]: d.c - close
  // [5]: d.v - volume
  // [6]: d.vw - volume weighted average price

  if (chartData.length === 0) {
    return <p className="p-4">Loading Performance Data...</p>;
  }

  const hasValidPrices = Array.isArray(chartData) && chartData.length > 0;
  const highestPrice = hasValidPrices
    ? Math.max(...chartData.map((price) => price.h))
    : "N/A";
  const lowestPrice = hasValidPrices
    ? Math.min(...chartData.map((price) => price.l))
    : "N/A";

  return (
    <div className="stock-performance">
      <div className="perf open">
        <h3>Open</h3>
        <p>{"$" + chartData[chartData.length - 1].o || "N/A"}</p>
      </div>

      <div className="perf high">
        <h3>High</h3>
        <p>{"$" + highestPrice.toFixed(2) || "N/A"}</p>
      </div>

      <div className="perf low">
        <h3>Low</h3>
        <p>{"$" + lowestPrice.toFixed(2) || "N/A"}</p>
      </div>

      <div className="perf prev-close">
        <h3>Prev Close</h3>
        <p>{"$" + chartData[chartData.length - 2].o.toFixed(2) || "N/A"}</p>
      </div>

      <div className="perf vol">
        <h3>Volume</h3>
        <p>{formatLargeNumber(chartData[chartData.length - 1].v) || "N/A"}</p>
      </div>

      <div className="perf mkt-cap">
        <h3>Market Cap</h3>
        <p>{"$" + formatLargeNumber(marketCap) || "N/A"}</p>
      </div>
    </div>
  );
};

const CompanyChart = ({ company, marketCap }) => {
  const { data, loading, error } = useStockData(company.ticker);

  if (loading) {
    return (
      <p className="min-h-[400px] grid place-items-center font-bold bg-black bg-opacity-50 animate-pulse">
        Loading chart...
      </p>
    );
  }

  if (error) {
    return <div className="min-h-[400px] space-y-4 p-8 text-center flex flex-col justify-center">
      <h1 className="font-bold">
        { error?.message || 'Something went wrong' }
      </h1>
      <p className="">
        { error?.response?.statusText || 'Please try again later' }
      </p>
    </div>;
  }

  if (!data || data.length === 0) {
    return (
      <p className="min-h-[400px] grid place-items-center font-bold bg-black bg-opacity-50">
        No chart data available
      </p>
    );
  }

  return <>
    <StockInfo ticker={company.ticker} chartData={data} />

    <StockChart chartData={data} />

    <StockPerformance chartData={data} marketCap={marketCap} />
  </>;
};

export default CompanyChart;
