// react
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Markdown from "react-markdown";

// global components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loading from "../../components/Loading/Loading";
import Summary from "../../components/Summary/Summary";

// local components
import ConsumerSearchChart from "./ConsumerSearchChart";
import DirectCompetitorChart from "./DirectCompetitorChart";
import SWOTAnalysis from "./SWOTAnalysis";

// hooks
import useProject from "../../hooks/useProject";
import useBrandAnalysis from "../../hooks/useBrandAnalysis";

// styles
import "./CompetitiveAnalysis.css";

const CompetitiveAnalysis = () => {
  const navigate = useNavigate();
  const uuid = useParams().projectId;
  const { project, loading: loadingProject } = useProject(uuid, navigate);
  const { brandData, loading: loadingBrand } = useBrandAnalysis(uuid, navigate);

  useEffect(() => {
    if (!uuid) {
      navigate("/companies");
      console.error("Redirecting due to missing company or project");
      return;
    }
  }, [uuid, navigate]);

  if (loadingProject || loadingBrand) {
    return <Loading />;
  }

  if (!brandData || Object.keys(brandData).length === 0) {
    return <p>No brand data available.</p>;
  }

  return (
    <div className="competitive-analysis page-base">
      <section className="page-section comp-landscape">
        <Breadcrumbs project={project} currentPage="Competitive Analysis" />

          <h1 className="inline-block grad">Competitive Landscape</h1>


        <div id="competitive-landscape" className="competitive-landscape">
          <Summary summary={brandData.competitive_landscape} />
        </div>
      </section>

      <section className="page-section direct-comp">
        <h2>Direct Competitors</h2>

        {brandData.competitors && brandData.competitors.length > 0 ? (
          <DirectCompetitorChart data={brandData.competitors} />
        ) : (
          <p>No competitor data available at this time</p>
        )}
      </section>

      <section className="page-section direct-comp">
        <h2>Market Demographic Analysis</h2>

        <div className="space-y-4 section-bg-light dark:section-bg-dark p-2 md:p-4">
          {brandData.demographic_analysis ? (
            <Markdown disallowedElements={['code', 'pre']}>{brandData.demographic_analysis}</Markdown>
          ) : (
            <p>No demographic analysis data available at this time</p>
          )}
        </div>
      </section>

      <section className="page-section direct-comp">
        <h2>Consumer Search Activity</h2>

        <div className="section-bg-light dark:section-bg-dark">
          {brandData.search_chart_data ? (
            <ConsumerSearchChart data={brandData.search_chart_data} />
          ) : (
            <p>No search activity data available at this time</p>
          )}
        </div>
      </section>

      <section className="page-section direct-comp">
        <h2>S.W.O.T. Analysis</h2>
        { brandData.swot_headlines && brandData.swot_analysis &&
          <SWOTAnalysis 
            swotHeadlines={brandData.swot_headlines}
            swotAnalysis={brandData.swot_analysis}
          />
        }
      </section>
    </div>
  );
};

export default CompetitiveAnalysis;
