// hooks
import useImageLoader from '../../hooks/useImageLoader';

const CompanyLogo = ({ src, alt, maxDimension = 200 }) => {
  const { imageStyle, isVisible, hasError } = useImageLoader(src, maxDimension);

  const content = hasError 
    ? <h2>{alt}</h2> 
    : <img 
        src={src} 
        alt={alt} 
        style={isVisible ? imageStyle : { ...imageStyle, visibility: 'hidden' }}
        referrerPolicy="no-referrer" />;

  return content;
};

export default CompanyLogo;
