import { Link } from "react-router-dom";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import '../Admin.css';

function LibraryCatalog() {
  return <>
    <div className="admin-page library-catalog container mx-auto">
    <nav aria-label="breadcrumbs">
        <ol className="flex gap-2 text-xs md:text-sm items-center">
          <li>
            <Link to="/admin">Admin</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li className="font-bold text-purple-400 text-lg">
            Library Catalog
          </li>
        </ol>
      </nav>
      <div className="page-header">
        <h1 className="grad">Library Catalog</h1>
      </div>

      <section className="admin-links">
        <Link to="/admin/condition-library">Condition Library</Link>
        <Link to="/admin/keyword-universe">Keyword Universe</Link>
        <Link to="/admin/forecast-leaderboard">Forecast Leaderboard</Link>
      </section>
    </div>
  </>
}

export default LibraryCatalog;