import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons"

import { $axios_old as axios } from "../../services/axiosInstance";

import ToolTipLabel from "../../components/ToolTipLabel/ToolTipLabel";

const VertexForm = ({ prompt, setPrompt, setResponse, setLoading, isDictating, setIsDictating }) => {
  const [formData, setFormData] = useState({
    prompt: "",
    model: "text-bison", // default model
    temperature: 0.5,     // 0 - 1
    token_limit: 256,     // 1 - 1024
    top_k: 40,            // 1 - 40
    top_p: 0.8,
    summarize: false,
  });

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSetPrompt = (event) => {
    setPrompt(event.target.value);
  };

  const handleSubmit = async (summarize = false, event) => {
    event.preventDefault();
    setLoading(true);
    setResponse('Working on that...');
  
    formData.token_limit = +formData.token_limit;
    formData.temperature = +formData.temperature;
    formData.top_k = +formData.top_k;
    formData.top_p = +formData.top_p;
  
    // set summarize based on summarize parameter
    formData.summarize = summarize;
    formData.prompt = prompt;
  
    try {
      const { data } = await axios.post("/api/genai/vertex", formData);
  
      setResponse(data.response);
  
    } catch (error) {
      setResponse("Something went wrong. Please try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleDictation = () => {
    setIsDictating(!isDictating);
  };

  return (
    <form>
    <div className="form-group">
    <label className="text-sm" htmlFor="model">Model</label>
      <select name="model" id="model">
        <option value="text-bison">text-bison (latest)</option>
        <option value="text-bison@001">text-bison@001</option>
      </select>
    </div>

    <div className="form-group">
      <ToolTipLabel
        labelText={"Temperature"}
        toolTipText={"Temperature controls the degree of randomness in token selection. Lower temperatures are good for prompts that expect a true or correct response, while higher temperatures can lead to more diverse or unexpected results. A temperature of 0 is deterministic: the highest probability token is always selected."}
        htmlFor={"temperature"}
        elValue={formData.temperature}
      />
      <input
        type="range"
        name="temperature"
        id="temperature"
        min="0"
        max="1"
        step="0.1"
        value={formData.temperature} 
        onChange={handleInputChange} 
      />
    </div>

    <div className="form-group">
      <ToolTipLabel
        labelText={"Token Limit"}
        toolTipText={"Token limit determines the maximum amount of text output from one prompt. A token is approximately four characters."}
        htmlFor={"token_limit"}
        elValue={formData.token_limit}
      />
      <input
        type="range"
        name="token_limit"
        id="token_limit"
        min="1"
        max="1024"
        step="1"
        value={formData.token_limit} 
        onChange={handleInputChange} 
      />
    </div>

    {/* <div className="form-group">
      <div className="label-group">
        <label htmlFor="length">
          Length
          <FontAwesomeIcon icon={faCircleQuestion} />
        </label>
        <span className="range-data">
          {formData.length}
        </span>
      </div>
      <input
        type="range"
        name="length"
        id="length"
        min="0"
        max="1024"
        step="16"
        value={formData.length}
        onChange={handleInputChange}
      />
    </div> */}

    <div className="form-group">
      <ToolTipLabel
        labelText={"Top-K"}
        toolTipText={"Top-k changes how the model selects tokens for output. A top-k of 1 means the selected token is the most probable among all tokens in the model's vocabulary (also called greedy decoding), while a top-k of 3 means that the next token is selected from among the 3 most probable tokens (using temperature)."}
        htmlFor={"top_k"}
        elValue={formData.top_k}
      />
      <input
        type="range"
        name="top_k"
        id="top_k"
        min="1"
        max="40"
        step="1"
        value={formData.top_k}
        onChange={handleInputChange}
      />
    </div>

    <div className="form-group">
      <ToolTipLabel
        labelText={"Top-P"}
        toolTipText={"Top-p changes how the model selects tokens for output. Tokens are selected from most probable to least until the sum of their probabilities equals the top-p value."}
        htmlFor={"top_p"}
        elValue={formData.top_p}
      />
      <input
        type="range"
        name="top_p"
        id="top_p"
        min="0"
        max="1"
        step="0.1"
        value={formData.top_p}
        onChange={handleInputChange}
      />
    </div>

    <div className="form-group prompt">
      <label className="text-sm" htmlFor="model">
        Prompt
      </label>
      <textarea
        name="prompt"
        id="prompt"
        cols="30"
        rows="10"
        placeholder="Write a prompt and then click submit. To summarize text from a web page paste link and click summarize"
        value={prompt}
        onChange={handleSetPrompt}
      ></textarea>
      <button className="dictation-btn" aria-label="start/stop dictation" onClick={handleDictation} type="button">
        <FontAwesomeIcon icon={faMicrophone} size="sm" />
      </button>
    </div>

    <div className="form-group button-group">
      <button type="submit" className="btn" onClick={(event) => handleSubmit(false, event)}>Submit</button>
      <button type="button" className="btn">Upload Text</button>
      <button type="button" className="btn" onClick={(event) => handleSubmit(true, event)}>Summarize</button>

    </div>
  </form>
  );
};

export default VertexForm;
