// react
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// hooks
import useCompanies from "../../hooks/useCompanies";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";

// components
import CompanyItem from "./CompanyItem";
import ProjectFilter from "../../components/ProjectFilter/ProjectFilter";
import CompaniesPagination from "./CompaniesPagination";

// styles
import "./Companies.css";

const Companies = () => {
  
  const {
    companies,
    loading,
    error,
    currentPage,
    setCurrentPage,
    totalPages,
    search,
    setSearch,
  } = useCompanies();

  const [selectedDifficulties, setSelectedDifficulties] = useState({
    lowest: true,
    low: true,
    moderate: true,
    high: true,
    highest: true,
  });

  // to focus on search input
  const searchInputRef = useRef(null);

  // Function to handle toggle changes
  const handleToggle = (difficulty) => {
    setSelectedDifficulties((prev) => ({
      ...prev,
      [difficulty]: !prev[difficulty],
    }));
  };

  const handleSetSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  // if (error) return <p>Error loading companies!</p>;

  return (
    <div className="companies-page page-base">
      <div className="page-header">
        <div className="col-1">
          <h1 className="grad">Companies</h1>
          <p>Discover Opportunities</p>
        </div>

        <div className="col-2">
          <Link className="header-btn" to="/project-status">
            <span className="hidden md:inline-block">Update Queue</span>
            <FontAwesomeIcon icon={faClockRotateLeft} />
          </Link>
        </div>
      </div>

      <div className="flex flex-col my-2 md:my-4 md:flex-row gap-2">
        <form action="" className="search-bar flex-1" role="search">
          <label className="mb-2 text-xs opacity-0" htmlFor="search">
            Company Search
          </label>
          <input
            className={`w-full p-4 rounded-lg shadow-md dark:shadow-none ${ error ? "cursor-not-allowed" : "" }`}
            type="text"
            value={ search }
            onChange={handleSetSearch}
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Search for Companies"
            disabled={ error }
            ref={searchInputRef}
          />
        </form>

        <ProjectFilter
          selectedDifficulties={selectedDifficulties}
          handleToggle={handleToggle}
        />
      </div>

      <ul
        className={`company-list ${loading ? "animate-pulse cursor-wait" : ""}`}
      >
        {companies?.length > 0 ? (
          companies
            .map((company, index) => (
              <CompanyItem
                key={`${company.uuid}-${index}`}
                company={company}
                selectedDifficulties={selectedDifficulties}
              />
            ))
        ) : loading ? (
          <p>Loading...</p>
        ) : error ? <div className="text-center py-16">
          <h1>Error loading companies!</h1>
          <p className="font-mono">{error.message}</p>
        </div> : (
          <p>No companies available for the current criteria.</p>
        )}
      </ul>

      <CompaniesPagination
        loading={loading}
        error={error}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />

    </div>
  );
};

export default Companies;
