// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// styles
import './KeywordItem.css';

const KeywordItem = ({ keyword, onRemove, disabled }) => {
  return (
    <div className="keyword-item">
      <span>{keyword}</span>

      { onRemove && (
        <button 
          type="button" 
          className="remove-btn" 
          onClick={() => onRemove()} 
          disabled={disabled}
          data-testid={`remove-${keyword}`}
        >
          <FontAwesomeIcon icon={faTimes} size="xs" />
          <span className="sr-only">Remove { keyword }</span>
        </button>
      )}
    </div>
  );
}

export default KeywordItem;