import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import Highcharts from "highcharts";

require('highcharts/modules/wordcloud.js')(Highcharts);

// data: project.wordcloud_data
const Wordcloud = ({ data }) => {
  if (!data || data.length === 0) {
    return <p className="px-4 py-8 font-bold text-center">Wordcloud data is not available.</p>
  }

  const chartOptions = (data) => {
    return {
      gui: {
        layouts: [
          {
            rows: [
              {
                cells: [
                  {
                    id: "word-cloud-cell",
                  },
                ],
              },
            ],
          },
        ],
      },
      components: [
        {
          type: "Highcharts",
          cell: 'word-cloud-cell',
          chartOptions: {
            chart: {
              height: 550
            },
            accessibility: {
                screenReaderSection: {
                    beforeChartFormat: '<h5>{chartTitle}</h5>' +
                        '<div>{chartSubtitle}</div>' +
                        '<div>{chartLongdesc}</div>' +
                        '<div>{viewTableButton}</div>'
                }
            },
            series: [{
                type: 'wordcloud',
                data,
                name: 'Occurrences'
            }],
            title: {
                text: 'Wordcloud',
                align: 'left'
            },
            subtitle: {
                text: 'Weighted based on search volume average, square-root scaled',
                align: 'left'
            },
            tooltip: {
                headerFormat: '<span style="font-size: 16px"><b>{point.key}</b></span><br>',
                pointFormatter: function () {

                  const colors = Highcharts.getOptions().colors;
                  const colorIndex = this.colorIndex || 0;
                  const color = colors[colorIndex];
                  const coloredDot = `<span style="color:${color};">● </span>`;
                  const formattedOriginalValue = Highcharts.numberFormat(this.original_value, 0);
          
                  return `${coloredDot}<b> Search Volume:</b> ${formattedOriginalValue}`;
              }
            }
        }
        },
      ],
    };
  };

  return (
    <ChartDashboard
      config={chartOptions(data)}
      async={true}
      id="word-cloud-container"
    />
  );
};

export default Wordcloud;
