import { useState, useEffect } from "react";
import { $axios } from '../services/axiosInstance';

const useBrandAnalysis = (projectId, navigate) => {
  const [brandData, setBrandData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBrandAnalysis = async () => {
      if(!projectId) {
        navigate('/companies');
        return;
      }
  
      try {
        const { data } = await $axios.get(`/company/brand_analysis/${projectId}/`);
        setBrandData(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(err);
        setLoading(false);
      }
    };

    fetchBrandAnalysis();
  }, [projectId, navigate]);

  return { brandData, loading, error };
}

export default useBrandAnalysis;