import { useEffect, useState } from "react";

import Highcharts from "highcharts";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";
import { formatMonths } from "../../../utils/chartUtils.js";

import { useTheme } from "../../../utils/ThemeManager.jsx";

import "./UniverseCharts.css";

const UniverseCharts = ({ data, category_type }) => {
  const { theme } = useTheme();

  if (!data || Object.keys(data).length === 0) {
    return <p className="min-h-[550px] px-4 py-8 font-bold grid place-content-center">Keyword Universe data is not available.</p>
  }

  const chartOptions = (data) => {
  // get names of all categories of the same type
  const categories = Object.entries(data)
  .filter(([key, value]) => value.category_type === category_type)
  .map(([key]) => key);

    // stacked bar chart
    const universeBar = Array.from({ length: 12 }, (_, monthIndex) => {
      return [ ...categories.map(category => data?.[category]?.search_volume_monthly_totals?.[monthIndex] || 0)];
    });
    
    // pie chart
    const totalCost = categories.reduce((sum, category) => {
      return sum + (data[category]?.search_volume_avg_annual_total || 0);
    }, 0);

    const universePie = categories.map(category => {
      const cost = data[category]?.search_volume_avg_annual_total || 0;
      
      return {
        name: category.replace('Uncategorized - ', ''), 
        y: (cost / totalCost) * 100, 
        visible: cost > 1
      };
    });

    return {
      dataPool: {
        connectors: [
          {
          id: 'universe-bar',
          type: 'JSON',
          options: {
            firstRowAsNames: false,
            columnNames: categories,
            data: universeBar,
          }
        },
        {
          id: 'universe-pie',
          type: 'JSON',
          options: {
            firstRowAsNames: false,
            columnNames: ['name', 'y', 'visible'],
            data: universePie,

          }
        },
      ]
      },
      gui: {
        layouts: [
          {
            rows: [
              {
                cells: [
                  {
                    id: `${category_type}-keywords-pie-cell`,
                    responsive: {
                      small: {
                        width: "100%"
                      },
                      medium: {
                        width: "40%"
                      },
                      large: {
                        width: "33.3%"
                      },
                    },
                  },
                  {
                    id: `${category_type}-keywords-stacked-cell`,
                    responsive: {
                      small: {
                        width: "100%"
                      },
                      medium: {
                        width: "60%"
                      },
                      large: {
                        width: "66.6%"
                      }
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      components: [
        {
          type: "Highcharts",
          cell: `${category_type}-keywords-stacked-cell`,
          sync: {
            visibility: true,
            highlight: true,
            extremes: true,
            crossfilter: true,
          },
          connector: {
            id: 'universe-bar',
            columnAssignment: [
              {
                seriesId: 'Branded',
                data: categories,
              },
          ]
          },
          chartOptions: {
            chart: {
              type: "column",
              height: 550
            },
            sync: {
              highlight: true
            },
            title: {
              text: null,
            },
            xAxis: {
              // use the first object of the data object 
              categories: formatMonths(data[Object.keys(data)[0]].search_volume_months),
            },
            yAxis: {
              title: {
                text: "Average Search Volume",
              },
              min: 0,
            },
            legend: {
              reversed: false,
              align: "center",
              verticalAlign: "top",
            },
            tooltip: {
              formatter: function () {
                  const colors = Highcharts.getOptions().colors;
                  const colorIndex = this.series.colorIndex || this.point.colorIndex || 0;
                  const color = colors[colorIndex];
                  const coloredDot = `<span style="color:${color};">● </span>`;
                  const formattedY = Highcharts.numberFormat(this.point.y, 0);
                  const formattedStackTotal = Highcharts.numberFormat(this.point.stackTotal, 0);
                  const formattedPercentage = Highcharts.numberFormat(this.point.percentage, 1);
          
                  return `
                      ${coloredDot}${this.series.name}: ${formattedY} (${formattedPercentage}%)
                      <br/>${coloredDot}Total: ${formattedStackTotal}
                  `;
              }
          },
            plotOptions: {
              series: {
                stacking: "normal",
              },
            },
          },
        },
        {
          type: "Highcharts",
          cell: `${category_type}-keywords-pie-cell`,
          chartOptions: {
            chart: {
              styledMode: false,
              type: "pie",
              height: 550,
            },
            title: {
              text: `${category_type} Average Search Volume by Category`,
              style: {
                color: 'contrast',
              }
            },
            tooltip: {
              enabled: true,
              stickOnContact: true,
              formatter: function () {
                const colorDot = `<span style="color:${this.point.color};">●</span>`;
                return `${colorDot} <b>${this.point.name}</b>: ${Highcharts.numberFormat(this.point.percentage, 1)}%`;
              },
            },
            subtitle: {
              text: "",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: [
                  {
                    enabled: true,
                    // format: "<b>{point.name}</b>",
                    formatter: function () {
                      if (this.point.visible && this.percentage > 0.5) {
                        return this.point.visible ? this.point.name : null;
                      }
                    },
                    style: {
                      color: theme === "dark" ? "white" : "black",
                    },
                  },
                  {
                    distance: -40,
                    enabled: true,
                    formatter: function () {
                      if (this.point.visible && this.percentage > 0.5) {
                        return `${Highcharts.numberFormat(this.point.percentage, 1)}%`;
                      }
                    },
                    style: {
                      color: theme === "dark" ? "black" : "white",
                      fontSize: "12px",
                    },
                  },
                ],
              },
            },
            series: [
              {
                name: "Percentage",
                type: "pie",
                data: universePie,
              },
            ],
          },
        },
      ],
    };
  };

  return <ChartDashboard config={chartOptions(data)} async={true} id={`${category_type}-keywords-bar-container`} />;
};

export default UniverseCharts;