import { useState } from "react";
import { Link } from "react-router-dom";

import { $axios } from "../../../services/axiosInstance";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

// global components
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import KeywordField from '../../../components/KeywordField/KeywordField'
import Loading from "../../../components/Loading/Loading";
import spinner from '../../../assets/img/spinner.svg';

// hook
import useKeywordCategories from "../../../hooks/useKeywordCategories";

import './KeywordCategories.css';

function KeywordCategories() {
  const [reorgLoading, setReorgLoading] = useState(false);
  const [reorgError, setReorgError] = useState(null);
  // input fields state
  const [newKeywords, setNewKeywords] = useState({
    // condition
    clinical_trials: "",
    support: "",
    preventative: "",
    treatment: "",
    diagnosis: "",
    symptoms: "",
    general_condition: "",
    // branded
    evaluation: "",
    payment: "",
    side_effects: "",
    usage: "",
    assistance: "",
    general_branded: "",
    // negative
    animals: "",
    explicit: "",
    other_global_condition_negatives: "",
  });

  // loading state for each category
  const [fieldLoading, setFieldLoading] = useState({
    // condition
    clinical_trials: false,
    support: false,
    preventative: false,
    treatment: false,
    diagnosis: false,
    symptoms: false,
    general_condition: false,
    // branded
    evaluation: false,
    payment: false,
    side_effects: false,
    usage: false,
    assistance: false,
    general_branded: false,
    // negative
    animals: false,
    explicit: false,
    other_global_condition_negatives: false,
  });

  // fetch keyword categories data from the hook
  const { keywordsForm, loading, dispatch } = useKeywordCategories();

  const handleUpdateKeywordField = (keyword, value) => {
    setNewKeywords((prevKeywords) => ({
      ...prevKeywords,
      [keyword]: value,
    }));
  };

  const handleAppendItem = async (list, item) => {
    if (!item) return;

    setFieldLoading((prev) => ({ ...prev, [list]: true }));

    const lowerCaseItem = item.toLowerCase();
    const updatedCategoryObj = {
      ...keywordsForm[list],
      keyword_set: [...keywordsForm[list].keyword_set, { value: lowerCaseItem }]
    };

    try {
      const { data } = await $axios.patch(`keywords_list/${updatedCategoryObj.uuid}/`, updatedCategoryObj);
      dispatch({ type: 'UPDATE_CATEGORY', field: list, value: data });
    } catch (error) {
      console.error(error);

      // check for 429 status code and display a message to the user
      if (error.response.status === 429) {
        alert('You have reached the maximum number of requests. Please try again later.');
      }
    } finally {
      setFieldLoading((prev) => ({ ...prev, [list]: false }));

      // reset the input field associated with the list
      setNewKeywords((prev) => ({ ...prev, [list]: "" }));
    }
  };

  const handleRemoveItem = async (list, index) => {
    setFieldLoading((prev) => ({ ...prev, [list]: true }));

    // remove the item from the list
    const updatedCategoryObj = {
      ...keywordsForm[list],
      keyword_set: keywordsForm[list].keyword_set.filter((_, i) => i !== index),
    };

    try {
      const { data } = await $axios.patch(`keywords_list/${updatedCategoryObj.uuid}/`, updatedCategoryObj);
      dispatch({ type: 'UPDATE_CATEGORY', field: list, value: data });
    } 
    catch (error) {
      console.error(error);

      // check for 429 status code and display a message to the user
      if (error.response.status === 429) {
        alert('You have reached the maximum number of requests. Please try again later.');
      }
    } finally {
      setFieldLoading((prev) => ({ ...prev, [list]: false }));
    }
  };

  const handleProjectReorganization = async () => {
    setReorgLoading(true);
    setReorgError(null);

    try {
      const res = await $axios.post('/company/recategorize_and_process_keywords_view/');
    } catch (error) {
      setReorgError('Failed to process request');
      console.error(error);
    } finally {
      setReorgLoading(false);
    }
  }

  return <>
    { loading && <Loading /> }

    <div className="admin-page keyword-categories-page page-base">
      <nav aria-label="breadcrumbs">
        <ol className="flex gap-2 text-xs md:text-sm items-center">
          <li>
            <Link to="/admin">Admin</Link>
            <FontAwesomeIcon className='ml-2 text-blueberry-500 dark:text-cherry-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li className="font-bold text-purple-400 text-lg">
            Keyword Categories
          </li>
        </ol>
      </nav>

      <div className="page-header">
        <h1 className="grad">Keyword Categories</h1>
      </div>

      <section>

        <div className="flex justify-between items-center">
          <h2>Condition</h2>

          {/* SLIDER */}
        </div>

        <div className="form-group">
          <div className="form-group-header">
            <h3>Clinical Trials (Trials)</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Clinical Trials Keyword"}
            list={keywordsForm.clinical_trials.keyword_set}
            type="clinical_trials"
            value={newKeywords.clinical_trials}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.clinical_trials}
          />
        </div>
        {/* Clinical Trials */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Support</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Support Keyword"}
            list={keywordsForm.support.keyword_set}
            type="support"
            value={newKeywords.support}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.support}
          />
        </div>
        {/* Support */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Preventative</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Preventative Keyword"}
            list={keywordsForm.preventative.keyword_set}
            type="preventative"
            value={newKeywords.preventative}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.preventative}
          />
        </div>
        {/* Preventative */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Treatment</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Treatment Keyword"}
            list={keywordsForm.treatment.keyword_set}
            type="treatment"
            value={newKeywords.treatment}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.treatment}
          />
        </div>
        {/* Treatment */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Diagnosis</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Diagnosis Keyword"}
            list={keywordsForm.diagnosis.keyword_set}
            type="diagnosis"
            value={newKeywords.diagnosis}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.diagnosis}
          />
        </div>
        {/* Diagnosis */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Symptoms</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Symptoms Keyword"}
            list={keywordsForm.symptoms.keyword_set}
            type="symptoms"
            value={newKeywords.symptoms}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.symptoms}
          />
        </div>
        {/* Symptoms */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>General Condition</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add General Condition Keyword"}
            list={keywordsForm.general_condition.keyword_set}
            type="general_condition"
            value={newKeywords.general_condition}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.general_condition}
          />
        </div>
        {/* General Condition */}
      </section>

      <section>
        <h2>Branded</h2>

        <div className="form-group">
          <div className="form-group-header">
            <h3>Evaluation</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Evaluation Keyword"}
            list={keywordsForm.evaluation.keyword_set}
            type="evaluation"
            value={newKeywords.evaluation}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.evaluation}
          />
        </div>
        {/* Evaluation */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Payment</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Payment Keyword"}
            list={keywordsForm.payment.keyword_set}
            type="payment"
            value={newKeywords.payment}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.payment}
          />
        </div>
        {/* Payment */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Side Effects</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Side Effects Keyword"}
            list={keywordsForm.side_effects.keyword_set}
            type="side_effects"
            value={newKeywords.side_effects}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.side_effects}
          />
        </div>
        {/* Side Effects */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Usage</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Usage Keyword"}
            list={keywordsForm.usage.keyword_set}
            type="usage"
            value={newKeywords.usage}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.usage}
          />
        </div>
        {/* Usage */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Assistance</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Assistance Keyword"}
            list={keywordsForm.assistance.keyword_set}
            type="assistance"
            value={newKeywords.assistance}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.assistance}
          />
        </div>
        {/* Assistance */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>General Branded</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add General Branded Keyword"}
            list={keywordsForm.general_branded.keyword_set}
            type="general_branded"
            value={newKeywords.general_branded}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.general_branded}
          />
        </div>
        {/* General Branded */}
      </section>

      <section>
        <h2>Negative</h2>

        <div className="form-group">
          <div className="form-group-header">
            <h3>Animals</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Animals Keyword"}
            list={keywordsForm.animals.keyword_set}
            type="animals"
            value={newKeywords.animals}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.animals}
          />
        </div>
        {/* Animals */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Explicit</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Explicit Keyword"}
            list={keywordsForm.explicit.keyword_set}
            type="explicit"
            value={newKeywords.explicit}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.explicit}
          />
        </div>
        {/* Explicit */}

        <div className="form-group">
          <div className="form-group-header">
            <h3>Other Negatives</h3>

            <div className="flex gap-2">
              {/* SLIDER??? */}
            </div>
          </div>

          <KeywordField
            placeholder={"Add Other Negatives Keyword"}
            list={keywordsForm.other_global_condition_negatives.keyword_set}
            type="other_global_condition_negatives"
            value={newKeywords.other_global_condition_negatives}
            onChange={handleUpdateKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={fieldLoading.other_global_condition_negatives}
          />
        </div>
        {/* Other Negatives */}
      </section>

      <section className="flex flex-col md:flex-row justify-between items-center space-y-0 gap-4">
        <div className="flex-1">
          <h2>Reorganize</h2>
          <p>Reorganize all priority projects with the latest keywords.</p>
        </div>
        <div className="flex-1 flex flex-col items-end justify-end">
          <button 
            className="reorg-btn" 
            onClick={handleProjectReorganization}
            disabled={reorgLoading}
            aria-label="Reorganize Projects"
            aria-busy={reorgLoading}
            >
              { reorgLoading 
                ? <img src={spinner} alt="loading spinner" className="w-7 h-7" />
                : 'Run' 
              }
            </button>
            <span className="text-red-500 italic text-center w-full max-w-[400px]" aria-live="assertive">{reorgError}</span>
        </div>
      </section>
    </div>
  </>
}

export default KeywordCategories;
