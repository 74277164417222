import { useEffect } from "react";

import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../../utils/ThemeManager.jsx";
import { getChartColors } from "../../../../utils/chartUtils.js";

Highcharts.themeDark = {
  colors: getChartColors('dark'),
};

Highcharts.themeLight = {
  colors: getChartColors('light'),
}

Highcharts.setOptions({
	plotOptions: {
		series: {
			animation: false
		}
	}
});

// data: monthly_data
// Search Volume by Month by Type
const SearchVolMonth = ({ data }) => {
  const { theme } = useTheme() || 'dark';

  useEffect(() => {
    theme === 'dark'
      ? Highcharts.setOptions(Highcharts.themeDark)
      : Highcharts.setOptions(Highcharts.themeLight)
  }, [theme]);

  if (!data || Object.keys(data).length === 0) {
    return <p className="px-4 py-8 font-bold text-center">Monthly Search Volume data is not available.</p>
  }

  return <ChartDashboard config={chartOptions(data)} async={true} id="monthly-search-volume" />;
};

export default SearchVolMonth;

const chartOptions = (data) => {
  return {
      gui: {
        layouts: [
          {
            rows: [
              {
                cells: [
                  {
                    id: "monthly-search-volume-cell",
                  },
                ],
              },
            ],
          },
        ],
      },
      components: [
        {
          type: "Highcharts",
          cell: "monthly-search-volume-cell",
          chartOptions: {
            chart: {
                type: 'column',
                styledMode: false,
                height: 550,
            },
            title: {
              text: 'Monthly Search Volume',
              align: 'left',
              style: {
                color: 'contrast',
              }
            },
            subtitle: {
                text:
                    'Source: <a target="_blank" ' +
                    'href="https://semrush.com">SEMRush</a>',
                align: 'left',
                style: {
                  color: 'contrast',
                  fontSize: '12px'
                }
            },
            legend: {
              align: 'center',
              verticalAlign: 'top',
              layout: 'horizontal',
              backgroundColor: 'rgb(248,248,248)',
              borderWidth: '1',
              borderColor: 'rgb(230,230,230)',
              itemStyle: {
                color: 'contrast',
              },
            },
            xAxis: {
              type: 'datetime',
              accessibility: {
                rangeDescription: 'Range: 12 months.'
              },
              dateTimeLabelFormats: {
                month: '%b' // Display abbreviated month name
              },
              categories: data.search_volume_months,
              gridLineColor: 'contrast',
              gridLineWidth: 1,
              labels: {
                style: {
                  color: 'contrast',
                }
              },
              style: {
                color: 'contrast',
              }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Monthly Search Volume',
                    style: {
                      color: 'contrast',
                    }
                },
                labels: {
                  style: {
                    color: 'contrast',
                  }
                },
            },
            tooltip: {
              useHTML: true,
              shadow: false,
              borderWidth: 2,
              headerFormat: '<table>',
              pointFormat: '<tr><th>{point.x:%b}</th></tr>' +
              '<tr><th>Monthly Volume:</th><td>{point.y}</td></tr>',
              footerFormat: '</table>',
              style: {
                color: 'contrast',
              }
            },
            plotOptions: {
              column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  pointPadding: 0,
                  groupPadding: .145,
                  /*animation: {
                      duration: 2000
                  },*/
              },
            },
            series: [
                {
                    name: 'Monthly Search Volume',
                    data: data.search_volume_monthly,
                    color: {
                      linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                      },
                      stops: [
                        [0, 'rgb(255, 20, 145, 1)'],
                        [.999, 'rgb(255, 114, 71, 1)']
                      ]
                  }, //end color
                  /*borderColor: '#303030',
                  borderWidth: 1,*/
                },
            ]
        }
        },
      ],
  }
}