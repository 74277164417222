import React, { useState } from "react";
import { Link } from "react-router-dom";

import { $axios } from "../../../services/axiosInstance";
import useConditions from "./hooks/useConditions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../../components/Loading/Loading";
import PaginationV2 from "../../../components/Pagination/PaginationV2";

import spinner from '../../../assets/img/spinner.svg'

import '../Admin.css';

function ConditionLibrary() {
  const [currentUrl, setCurrentUrl] = useState('/company/condition_analysis/');
  const {
    conditions,
    loading,
    error,
    next,
    previous,
    count,
    totalPages,
  } = useConditions(currentUrl);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshing, setRefreshing] = useState([]);

  const handleRefreshCondition = async (e) => {
    const uuid = e.currentTarget.value;
    setRefreshing([...refreshing, uuid]);

    // /django_api/company/condition_analysis/{uuid}/refresh_condition_keyword_categories_without_semrush/
    $axios.put(`/company/condition_analysis/${uuid}/refresh_condition_keyword_categories_without_semrush/`)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setRefreshing(refreshing.filter(item => item !== uuid));
      });
  }

  const handleNext = () => {
    if (next) {
      setCurrentUrl(next);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (previous) {
      setCurrentUrl(previous);
      setCurrentPage(currentPage - 1);
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="admin-page page-base condition-library">
    <nav aria-label="breadcrumbs">
      <ol className="flex gap-2 text-xs md:text-sm items-center">
        <li>
          <Link to="/admin">Admin</Link>
          <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
        </li>

        <li>
        <Link to="/admin/library-catalog">Library Catalog</Link>
          <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
        </li>

        <li className="font-bold text-purple-400 text-lg">
        Condition Library
        </li>
      </ol>
    </nav>

    <div className="page-header">
      <h1 className="grad">Condition Library</h1>
    </div>

      <section className="relative flex flex-col">
        {loading && (
          <div className="min-h-[550px] absolute inset-0 bg-black bg-opacity-50 grid place-items-center">
            <img className="h-12 w-12" src={spinner} alt="loading spinner" />
          </div>
        )}

        <ul>
          { conditions.map((condition, index) => {
              return (
                <li className="p-4 flex justify-between odd:bg-white odd:bg-opacity-10" key={condition.uuid}>
                  <Link key={index} to={`/admin/condition-profile/${condition.uuid}`}>
                    {condition.name}
                  </Link>

                  <button 
                    value={condition.uuid} 
                    onClick={handleRefreshCondition}
                    disabled={refreshing.includes(condition.uuid)}
                  >
                    <FontAwesomeIcon icon={faArrowsRotate} spin={refreshing.includes(condition.uuid)} />
                  </button>
                </li>
              )
            }) 
          }
        </ul>

        <PaginationV2
          count={count}
          next={next}
          previous={previous}
          totalPages={totalPages}
          currentPage={currentPage}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      </section>
    </div>
  )
}

export default ConditionLibrary;