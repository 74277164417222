import { useState } from "react";

import "./ModalSelector.css";

const ModelSelector = ({ service, setService, style }) => {
  const [hoveredService, setHoveredService] = useState(null);
  return (
    <div className="model-selector" style={style}>
      <div className="selector-container">
        <ul className="selector-list">
          <li className="selector-item">
            <button
              type="button"
              aria-haspopup="tooltip"
              aria-expanded={hoveredService === "openai" ? "true" : "false"}
              data-state={hoveredService === "openai" ? "open" : "closed"}
              className={
                "selector-button " + (service === "openai" ? "selected" : "")
              }
              onClick={() => setService("openai")}
              onMouseEnter={() => setHoveredService("openai")}
              onMouseLeave={() => setHoveredService(null)}
            >
              <div className="selector-button-content">
                <span className="relative max-[370px]:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    focusable="false"
                    role="presentation"
                  >

                    <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
                  </svg>
                </span>
                <span className="">
                  OpenAI
                </span>
              </div>
            </button>
          </li>
          <li className="selector-item">
            <button
              type="button"
              aria-haspopup="tooltip"
              aria-expanded={ hoveredService === "vertex" ? "true" : "false"}
              data-state={ hoveredService === "vertex" ? "open" : "closed"}
              className={
                "selector-button" + (service === "vertex" ? " selected" : "")
              }
              onClick={() => setService("vertex")}
              onMouseEnter={() => setHoveredService("vertex")}
              onMouseLeave={() => setHoveredService(null)}
            >
              <div className="selector-button-content">
                <span className="relative max-[370px]:hidden">
                  <svg
                    viewBox="0 0 32 32"
                    fit=""
                    height="16"
                    width="16"
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                    role="presentation"
                  >
                    <path
                      d="M26.69 18.53a1 1 0 00-1.4-.22L16 25.17v.29a1 1 0 110 1.91v.05a1 1 0 00.6-.19l9.88-7.3a1 1 0 00.21-1.4z"
                      fill="currentColor"

                    ></path>
                    <path
                      d="M16 27.37a1 1 0 110-1.91v-.29l-9.29-6.86a1 1 0 00-1.4.22 1 1 0 00.21 1.4l9.89 7.3a1 1 0 00.59.19v-.05z"
                      fill="currentColor"

                    ></path>
                    <path
                      d="M16 24.46a2 2 0 102 2 2 2 0 00-2-2zm0 2.91a1 1 0 111-.95 1 1 0 01-1 .95z"
                      fill="currentColor"

                    ></path>
                    {/* book */}
                    <path
                      d="M8 8.14a1 1 0 01-1-1V4.63a1 1 0 112 0v2.51a1 1 0 01-1 1z"
                      fill="currentColor"
                      opacity={1}
                    ></path>
                    <circle cx="7.97" cy="16" r="1.01" fill="currentColor" opacity={1}></circle>
                    <circle
                      cx="7.97"
                      cy="13.05"
                      r="1.01"
                      fill="currentColor"
                      opacity={1}
                    ></circle>
                    <circle
                      cx="7.97"
                      cy="10.09"
                      r="1.01"
                      fill="currentColor"
                      opacity={1}
                    ></circle>
                    <path
                      d="M24 11.07a1 1 0 01-1-1V7.55a1 1 0 012 0v2.52a1 1 0 01-1 1z"
                      fill="currentColor"
                      opacity={0.3}
                    ></path>
                    <circle
                      cx="24.03"
                      cy="16.01"
                      r="1.01"
                      fill="currentColor"
                      opacity={0.3}
                    ></circle>
                    <circle
                      cx="24.03"
                      cy="13.02"
                      r="1.01"
                      fill="currentColor"
                      opacity={0.3}
                    ></circle>
                    <circle
                      cx="24.03"
                      cy="4.63"
                      r="1.01"
                      fill="currentColor"
                      opacity={0.3}
                    ></circle>
                    
                    <path
                      d="M16 20a1 1 0 01-1-1v-2.54a1 1 0 012 0V19a1 1 0 01-1 1z"
                      fill="currentColor"
                      opacity={0.5}
                    ></path>
                    <circle cx="16" cy="21.93" r="1.01" fill="currentColor" opacity={0.5}></circle>
                    <circle cx="16" cy="13.51" r="1.01" fill="currentColor" opacity={0.5}></circle>
                    <circle cx="16" cy="10.56" r="1.01" fill="currentColor" opacity={0.5}></circle>
                    
                    <path
                      d="M20 14.05a1 1 0 01-1-1v-2.51a1 1 0 112 0v2.51a1 1 0 01-1 1z"
                      fill="currentColor"
                      opacity={0.4}
                    ></path>
                    <circle
                      cx="20.02"
                      cy="7.58"
                      r="1.01"
                      fill="currentColor"
                      opacity={0.4}
                    ></circle>
                    <circle
                      cx="20.02"
                      cy="18.92"
                      r="1.01"
                      fill="currentColor"
                      opacity={0.4}
                    ></circle>
                    <circle
                      cx="20.02"
                      cy="15.97"
                      r="1.01"
                      fill="currentColor"
                      opacity={0.4}
                    ></circle>
                    <circle
                      cx="11.98"
                      cy="18.92"
                      r="1.01"
                      fill="currentColor"
                      opacity={0.8}
                    ></circle>
                    <circle
                      cx="11.98"
                      cy="10.56"
                      r="1.01"
                      fill="currentColor"
                      opacity={0.8}
                    ></circle>
                    <circle
                      cx="11.98"
                      cy="7.58"
                      r="1.01"
                      fill="currentColor"
                      opacity={0.8}
                    ></circle>
                    <path
                      d="M12 17a1 1 0 01-1-1v-2.54a1 1 0 012 0V16a1 1 0 01-1 1z"
                      fill="currentColor"
                      opacity={0.8}
                    ></path>
                  </svg>
                </span>
                <span>
                  Vertex
                </span>
              </div>
            </button>
          </li>
        </ul>
      </div>

      <div 
        className="tooltip-container" 
        role="tooltip"
        style={{ 
          opacity: hoveredService ? 1 : 0, 
          pointerEvents: hoveredService ? "all" : "none", 
        }}
        aria-hidden={hoveredService ? false : true}
      >
        <div className="tooltip" id="tooltip">
          {hoveredService === "openai" ?
            <span>
              <strong>ChatGPT</strong> is an advanced AI developed by OpenAI. Trained on diverse data sources, it's capable of understanding and generating human-like text. It's knowledgeable up to September 2021 and can engage in diverse discussions from tech to literature.
            </span>
           : 
            <span>
              <strong>Vertex AI</strong> is a comprehensive AI platform developed by Google Cloud. Built to streamline the machine learning workflow, it enables efficient model building, training, and deployment. It offers unified access to Google's wide array of AI and ML tools, from AutoML to pre-trained models. This platform, launched in May 2021, is designed to empower businesses with scalable AI applications, facilitating everything from data management to predictive analytics.
            </span>
          }
        </div>
      </div>
    </div>
  );
};

export default ModelSelector;
