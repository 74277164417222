import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HC_more from 'highcharts/highcharts-more';
import HighchartsSankey from 'highcharts/modules/sankey';
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "../../utils/ThemeManager.jsx";

import "./ConsumerSearchChart.css";

HC_more(Highcharts);
HighchartsSankey(Highcharts);
highchartsAccessibility(Highcharts);

// function for getting chart options
const getChartOptions = (data, theme) => {
  // convert data to an array of objects
  data = Object.values(data);

  return {
      chart: {
        backgroundColor: null,
        className: `highcharts-${theme}`,
      },
      title: {
        text: "",
        style: {
          color: '#fff',
        }
      },
      tooltip: {
        style: {
          color: theme === "dark" ? '#fff' : '#000',
        }
      },
      accessibility: {
        point: {
          valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
        }
      },
      series: [{
        keys: ['from', 'to', 'weight'],
        data: data,
        type: 'sankey',
        name: 'Search activity'
      }]
    };
};

const ConsumerSearchChart = ({ data }) => {
  const { theme } = useTheme();
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartOptions(getChartOptions(data, theme));
  }, [data, theme]);

  return <HighchartsReact 
    containerProps={{ id: "consumer-search-chart", 'data-testid': "consumer-search-chart" }} 
    highcharts={Highcharts} 
    options={chartOptions} 
  />;
};

export default ConsumerSearchChart;