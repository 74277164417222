import React, { useEffect, useRef, useState } from 'react'
import { useSprings, animated } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import { useDrag } from 'react-use-gesture'
import clamp from 'lodash.clamp'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";

import './ForecastCharts.css'

function ForecastCharts({ children, className }) {
  const childrenArray = React.Children.toArray(children);
  const index = useRef(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [keyMap, setKeyMap] = useState({
    ArrowRight: false,
    ArrowLeft: false
  })
  const [ref, { width }] = useMeasure()
  const [props, api] = useSprings(
    childrenArray.length,
    i => ({
      x: i * width,
      display: 'block',
    }),
    [width]
  )
  const bind = useDrag(({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
    if (active && distance > width / 2) {
      index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, childrenArray.length - 1)
      cancel()
    }
    api.start(i => {
      if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
      const x = (i - index.current) * width + (active ? mx : 0)
      return { x, display: 'block' }
    })

    setActiveIndex(index.current); // Update the active index
  })

  useEffect(() => {
    setActiveIndex(index.current);
  }, [index.current]);

  // event listner for key left and key right
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        index.current = clamp(index.current + 1, 0, childrenArray.length - 1);
        api.start(i => {
          if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
          const x = (i - index.current) * width
          return { x, display: 'block' }
        });
        setActiveIndex(index.current); // Update the active index
        setKeyMap({ ArrowRight: true, ArrowLeft: false });
      }

      if (e.key === 'ArrowLeft') {
        index.current = clamp(index.current - 1, 0, childrenArray.length - 1);
        api.start(i => {
          if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
          const x = (i - index.current) * width
          return { x, display: 'block' }
        });
        setActiveIndex(index.current); // Update the active index
        setKeyMap({ ArrowRight: false, ArrowLeft: true });
      }
    }

    const handleKeyUp = (e) => {
      if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
        setKeyMap({ ArrowRight: false, ArrowLeft: false });
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [width, api]);

  return (
    <div className={`forecast-container ${ className }`}>
      <div ref={ref} className="swipe-wrapper">
        {props.map(({ x, display }, i) => (
          <animated.div className="swipe-page" {...bind()} key={i} style={{ display, x }}>
            <animated.div className="swipe-page-content">
              {childrenArray[i]}
            </animated.div>
          </animated.div>
        ))}
      </div>

      <div className='flex justify-between gap-4 pb-2 px-8'>
        <div className="navigation-dots flex items-center justify-center gap-2">
          {childrenArray.map((_, i) => (
            <div
              key={i}
              className={`dot ${activeIndex === i ? 'active' : ''}`}
              onClick={() => {
                index.current = i;
                api.start(i => {
                  if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
                  const x = (i - index.current) * width
                  return { x, display: 'block' }
                });
                setActiveIndex(i); // Update the active index
              }}
            />
          ))}
        </div>

        <div className="navigation-arrows flex items-center justify-center gap-8 text-2xl">
          <button
            className={`arrow left nav-button ${keyMap.ArrowLeft ? 'active' : ''}`}
            onClick={() => {
              index.current = clamp(index.current - 1, 0, childrenArray.length - 1);
              api.start(i => {
                if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
                const x = (i - index.current) * width
                return { x, display: 'block' }
              });
              setActiveIndex(index.current); // Update the active index
            }}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </button>

          <button
            className={`arrow right nav-button ${keyMap.ArrowRight ? 'active' : ''}`}
            onClick={() => {
              index.current = clamp(index.current + 1, 0, childrenArray.length - 1);
              api.start(i => {
                if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
                const x = (i - index.current) * width
                return { x, display: 'block' }
              });
              setActiveIndex(index.current); // Update the active index
            }}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </button>
        </div>
      </div>

    </div>
  )
}

export default ForecastCharts
