import { useState, useEffect } from 'react';
import { $axios } from '../services/axiosInstance';

const useCompanies = () => {
  const initialSearch = sessionStorage.getItem('search') || '';
  const [search, setSearch] = useState(initialSearch);
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const ITEMS_PER_PAGE = 10;
  const DEBOUNCE_DELAY = 500;

  const fetchCompanies = (page) => {
    setLoading(true);
    setError(null);
    
    const baseURL = '/company/project/search/';
    const pageQuery = `?page=${page}`;
    const searchQuery = search ? `&search=${search}` : '';

    $axios.get(`${baseURL}${pageQuery}${searchQuery}`)
      .then(({ data }) => {
        setCompanies(data.results);
        setTotalPages(Math.ceil(data.count / ITEMS_PER_PAGE)); // Assumes each page has the same number of results
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    const debounceFetch = setTimeout(() => {
      fetchCompanies(currentPage);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(debounceFetch); // Cleanup on unmount or re-run
  }, [currentPage]);

  useEffect(() => {
    sessionStorage.setItem('search', search);
    
    const debounceFetch = setTimeout(() => {
      fetchCompanies(1);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(debounceFetch); // Cleanup on unmount or re-run
  }, [search]);

  return { companies, loading, error, currentPage, setCurrentPage, totalPages, search, setSearch };
};

export default useCompanies;