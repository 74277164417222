import { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import { formatCurrency, formatLargeNumber } from "../../../utils/tableUtils";

import Loading from "../../../components/Loading/Loading";
import Progress from "./Progress";
import SearchVolMonth from "./Charts/SearchVolMonth";

import useKeyword from "./hooks/useKeyword";

import '../Admin.css';
import './KeywordProfile.css'

function KeywordProfile() {
  const { navigate } = useNavigate();
  const { keywordId } = useParams();
  const { keyword, searchUrl, loading, error } = useKeyword(keywordId);

  useEffect(() => {
    if (!keywordId) {
      // If not present, navigate to /admin/keyword-universe
      navigate('/admin/keyword-universe');
      return;
    }
  }, []);

  if (loading) return <Loading />

  if (error) {
    return <div className="text-center">
      <h1>Error fetching data</h1>

      <p className="text-red-500">{error.message}</p>
    </div>
  }

  return <>
    <div className="admin-page keyword-profile page-base">
      <nav aria-label="breadcrumbs">
        <ol className="flex gap-2 text-xs md:text-sm items-center">
          <li>
            <Link to="/admin">Admin</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li>
          <Link to="/admin/library-catalog">Library Catalog</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li>
          <Link to="/admin/keyword-universe">Keyword Universe</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li className="font-bold text-purple-400 text-lg">
            Keyword Profile
          </li>
        </ol>
      </nav>


      <h1>
        <a 
          className="capitalize flex gap-2 items-center" 
          href={searchUrl} 
          target="_blank"
          rel="noreferrer noopener"          
        >
          <span>{ keyword.value }</span>
          <span className="sr-only">Opens in an new tab</span>
          <span className="text-white text-sm">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </span>
        </a>
      </h1>

      <section className="flex flex-col gap-4 md:gap-8">
        <div className="flex flex-col md:flex-row gap-4">
          <Progress 
            id="keyword_difficulty"
            label="Keyword Difficulty" 
            value={keyword.latest_monthly_data.keyword_difficulty} 
            max="100" 
          />

          <Progress 
            id="ad_density"
            label="Ad Density" 
            value={keyword.latest_monthly_data.google_ads_competitive_density} 
            max="1" 
          />
        </div>

        <div className="info">
          <div className="info-item">
            <h2>AMSV</h2>
            <p>{formatLargeNumber(keyword.latest_monthly_data.search_volume_avg)}</p>
          </div>

          <div className="info-item">
            <h2>Intent</h2>
            <p>{ keyword.latest_monthly_data.keyword_intent || 'N/A' }</p>
          </div>

          <div className="info-item">
            <h2>CPC</h2>
            <p>{formatCurrency(keyword.latest_monthly_data.google_ads_cpc_avg)}</p>
          </div>

          <div className="info-item">
            <h2>Type</h2>
            <p>{ keyword.keyword_type }</p>
          </div>

          <div className="info-item flex flex-col">
            <h2>Category</h2>
            <ul className={`flex-1 ${ keyword.categories.length > 1 ? 'list-disc' : ''} px-4 content-center`}>
              { keyword.categories.length > 0 
                ? keyword.categories.map((category, index) => {
                  return <li className="md:text-center" key={index}>{ category }</li>
                }
              )
              : <li>N/A</li>
              }
            </ul>
          </div>

          <div className="info-item opacity-40">
            <h2>Subcategory</h2>
            <p>--</p>
          </div>
        </div>

        { keyword.latest_monthly_data.search_volume_monthly.every(num => num === 0)
          ? <p className="p-8 text-center bg-opacity-50 border border-white border-opacity-50 rounded">No monthly search volume data available.</p>
          : <SearchVolMonth data={keyword.latest_monthly_data} />
        }

        <div className="space-y-4">
          <div>
            <h2>Keyword Intent</h2>
            <p className="font-semibold">Why would someone perform this search?</p>
          </div>

          <div>
            Why would someone be searching google for the keyword, "{ keyword.value }"? Limit response to bullets and 200 words.
          </div>
        </div>

        <div className="space-y-4">
          <div>
            <h2>SERP Features</h2>
            <p className="font-semibold">What SERP features exist?</p>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
            { keyword.latest_monthly_data.media.length > 0 
              ? keyword.latest_monthly_data.media.map((media, index) => {
                return <div className="border border-white p-4 text-center rounded" key={index}>
                  <h3>{ media }</h3>
                </div>

            })
            : <p className="p-4 text-center col-span-full">No SERP features found.</p>
          }
          </div>
        </div>

      </section>
    </div>
  </>
}

export default KeywordProfile;