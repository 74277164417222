import { useState, useEffect } from 'react';

export const useImageLoader = (src, maxDimension) => {
  const [imageStyle, setImageStyle] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;

    image.onload = () => {
      const aspectRatio = image.width / image.height;

      if (aspectRatio >= 1) {
        // Landscape or square image
        setImageStyle({ width: `${maxDimension}px`, visibility: 'visible' });
      } else {
        // Portrait image
        setImageStyle({ height: `${maxDimension}px`, visibility: 'visible' });
      }

      setIsVisible(true);
    };

    image.onerror = () => {
      setHasError(true);
    };
  }, [src, maxDimension]);

  return { imageStyle, isVisible, hasError };
};

export default useImageLoader;