import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { formatCurrency } from "../../../../utils/tableUtils.js";
import { isValidData } from "../../../../utils/chartUtils.js";

import "./Type.css";

const requiredProperties = [
  "Brand Subtotal.estimated_monthly_cost_trend",
  "Condition Subtotal.estimated_monthly_cost_trend",
  "Grand Total.search_volume_months",
];

// data: project.keyword_universe
function Type({ data }) {
  if (!isValidData(data, requiredProperties)) {
    return <p className="min-h-[550px] px-4 py-8 font-bold grid place-content-center">Estimated spend by type data is not available.</p>
  };

  return (
    <div className="estimated-spend-type">
      <ChartDashboard config={chartOptions(data)} id="estimated-spend-type-container" />
    </div>
  );
}

export default Type;

const chartOptions = (data) => {
  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `estimated-spend-type-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `estimated-spend-type-cell`,
        chartOptions: {
          chart: {
            type: "column",
            styledMode: false,
            height: 550,
          },
          title: {
            text: "Estimated Monthly Spend by Type",
            align: "left",
          },
          xAxis: {
            categories: data['Grand Total'].search_volume_months,
          },
          yAxis: {
            min: 0,
            title: {
              text: "Spend in Millions",
            },
            stackLabels: {
              enabled: true,
              formatter: function () {
                // dollar sign and 2 decimal places
                return formatCurrency(this.total);
              },
              style: {
                fontSize: "14px", 
                fontWeight: "normal",
              }
            },
          },
          legend: {
            align: "left",
            verticalAlign: "top",
            floating: true,
            x: 60,
            y: 50,
          },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            formatter: function () {
              const colorDot = `<span style="color:${this.point.color};">●</span>`;
              return `<b>${this.x}</b><br/>${colorDot} ${this.series.name}: ${formatCurrency(this.y)}`;
            },
            style: {
              color: 'contrast'
            },
          },
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
          series: [
            {
              name: "Branded",
              data: data["Brand Subtotal"].estimated_monthly_cost_trend
            },
            {
              name: "Condition",
              data: data["Condition Subtotal"].estimated_monthly_cost_trend
            },
          ],
        },
      },
    ],
  };
};
