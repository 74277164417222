import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import './ConversionFunnel.css';

// data: project.keyword_universe
function ConversionFunnel({ data }) {
  if (!data || !Object.keys(data).length) {
    return <p className="min-h-[550px] px-4 py-8 font-bold grid place-content-center">Conversion funnel data is not available.</p>
  };

return <div className="conversion-funnel">
  <div className="content">
    <div className="col col-1">
      <ChartDashboard config={chartOptions(data)} id="conversion-funnel-container" />
    </div>
  </div>
</div>
} 

export default ConversionFunnel;

const chartOptions = (data) => {
  // Collect all objects with a category_type of "Branded"
  const brandedSeriesKeys = Object.entries(data)
    .filter(([key, value]) => value.category_type === "Branded");

  // Generate dynamic series for all "Branded" categories
  const dynamicSeries = brandedSeriesKeys.map(([key, value]) => ({
    name: key,
    data: [
      value.estimated_visits || 0,
      value.estimated_tass_starts || 0,
      value.estimated_tass_completes || 0,
      value.estimated_tass_ok || 0,
      value.estimated_purchases || 0,
    ]
  }));

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `conversion-funnel-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `conversion-funnel-cell`,
        chartOptions: {
          chart: {
            type: 'bar',
            height: 550,
            styledMode: false,
          },
          title: {
            text: 'ACNU Conversion Funnel',
            align: 'left',
          },
          subtitle: {
            text: `There were ${ data['Grand Total'].search_volume_avg_annual_total.toLocaleString() } searches.`,
            align: 'left',
          },
          xAxis: {
            categories: ['Site Visits', 'Complete "Take Quiz"', 'Complete "Get Result"', 'Complete "Ok to Use"', 'Set Up Account & Purchase'],
            title: {
              text: null
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: null
            },
          },
          tooltip: {
            valueSuffix: '',
            formatter: function() {
              // precision to 2 decimal places
              const colors = Highcharts.getOptions().colors;
              const colorIndex = this.point.colorIndex || 0;
              const color = colors[colorIndex];

              const value = this.y.toLocaleString(undefined, { maximumFractionDigits: 2 });
              return `<span style="color:${color}">●</span> <b>${this.series.name}</b><br>${this.x}: ${value}`;
            }
          },
          plotOptions: {
            bar: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
              }
            }
          },
          series: dynamicSeries,
        }
      },
    ],
  };
};