import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom"
import Markdown from "react-markdown";
import { getChartColors } from "../../../utils/chartUtils";
import { useTheme } from "../../../utils/ThemeManager";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../../components/Loading/Loading";

import useCondition from "./hooks/useCondition";

import '../Admin.css';
import './ConditionProfile.css';

function ConditionProfile() {
  const { theme } = useTheme();
  const { navigate } = useNavigate();
  const { conditionId } = useParams();
  const { condition, loading, error } = useCondition(conditionId);
  const [activeTab, setActiveTab] = useState(0);

  const [colors, setColors] = useState(getChartColors(theme));
  const tabs = [
    { label: 'Keyword Universe', color: colors[0] },
    { label: 'Condition', color: colors[1] },
    { label: 'Competition', color: colors[2] },
    { label: 'Forecast', color: colors[3] },
  ];

  const handleActiveTab = (e) => {
    const tab = Number(e.currentTarget.dataset.index);
    setActiveTab(tab);
  }

  useEffect(() => {
    if (!conditionId) {
      // If not present, navigate to /admin/condition-library
      navigate('/admin/condition-library');
      return;
    }
  }, []);

  useEffect(() => {
    setColors(getChartColors(theme));
  }, [theme]);

  if (loading) return <Loading />

  if (error) {
    return <div className="text-center">
      <h1>Error fetching data</h1>

      <p className="text-red-500">{error.message}</p>
    </div>
  }

  return <>
    <div className="admin-page page-base condition-profile">
      <nav aria-label="breadcrumbs">
        <ol className="flex gap-2 text-xs md:text-sm items-center">
          <li>
            <Link to="/admin">Admin</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li>
          <Link to="/admin/library-catalog">Library Catalog</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li>
          <Link to="/admin/condition-library">Condition Library</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li className="font-bold text-purple-400 text-lg">
          { condition.name }
          </li>
        </ol>
      </nav>

      <div>
        <div className="page-header mb-2">
          <h1 className="grad">{ condition.name }</h1>
        </div>

        <section>
          <Markdown>{ condition.condition_overview }</Markdown>
        </section>
      </div>

      <div>
        <h2>Interest</h2>
        <section className="">
          <div className="tab-group">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`tab bg-opacity-10 ${activeTab === index ? 'active' : ''}`}
                style={{ '--color': tab.color }}
                data-index={index}
                onClick={handleActiveTab}
              >
                <div className="bg" />
                <h3>{tab.label}</h3>
              </button>
            ))}
          </div>

          <div className="tab-content">
            <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>
              <h3>Keyword Universe</h3>
            </div>

            <div className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}>
              <h3>Condition</h3>
            </div>

            <div className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}>
              <h3>Competition</h3>
            </div>

            <div className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}>
              <h3>Forecast</h3>
            </div>
          </div>
        </section>
      </div>

      <div>
        <h2>Diagnosis Requirements</h2>
        <section className="">
          <Markdown>{ condition.basic_diagnosis_criteria }</Markdown>
        </section>
      </div>

      <div>
        <h2>Condition Keywords</h2>
        <section className=""></section>
      </div>

      <div>
        <h2>Medications Treating { condition.name }</h2>
        <section className=""></section>
      </div>
    </div>
  </>
}

export default ConditionProfile;