import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// need these when summary is added back
// import Markdown from "react-markdown";
// import Summary from "../../components/Summary/Summary";
import ForecastCharts from "../ACNUFeasibility/Charts/Forecast/ForecastCharts";
import EstimatedSpendType from "./Charts/EstimatedSpend/Type";
import EstimatedSpendCategory from "./Charts/EstimatedSpend/Category";
import UniverseCharts from "./Charts/UniverseCharts";
import UniverseTable from "./Charts/UniverseTable/UniverseTable";
import ConsumerJourney from "../Project/SearchVolMonthType/SearchVolMonthType";
import Wordcloud from "./Charts/Wordcloud/Wordcloud";
import TopQuestions from "./Charts/TopQuestions/TopQuestions";

import useProject from "../../hooks/useProject";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loading from "../../components/Loading/Loading";

import "./KeywordUniverse.css";

const KeywordUniverse = () => {
  const navigate = useNavigate();
  const uuid = useParams().projectId;
  const { project, loading } = useProject(uuid, navigate);

  useEffect(() => {
    // if no uuid, redirect to companies page
    if (!uuid) {
      navigate("/companies");
      console.error("Redirecting due to missing company or project");
      return;
    }
  }, [uuid, navigate]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="keyword-universe-page page-base">
      <h1 className="sr-only">Keyword Universe</h1>
      <Breadcrumbs project={project} currentPage="Keyword Universe" />

      <section>
        <h2>Overview</h2>

        <div className="section-bg-light dark:section-bg-dark p-4 space-y-2">
          <p>The Keyword Universe is a combination of all the keywords found in both the Condition and Branded Types.</p>
          <p>Each Type includes nested Categories like Diagnosis (Condition) or Side Effects (Branded).</p>
          {/* <Summary summary={project?.keyword_universe_summary} /> */}
        </div>
      </section>

      <section>
        <h2>Consumer Journey</h2>

        <div className="section-bg-light dark:section-bg-dark md:p-4 space-y-4;">
          <ForecastCharts className="keyword-charts">
            <div>
              <UniverseCharts data={project?.keyword_universe} category_type="Condition" />
            </div>

            <div>
              <UniverseCharts data={project?.keyword_universe} category_type="Branded" />
            </div>

            <div>
              <ConsumerJourney data={project?.keyword_universe} />
            </div>

            <div>
              <Wordcloud data={project?.wordcloud_data} />
            </div>
          </ForecastCharts>
        </div>
      </section>

      <section>
        <h2>Top Questions</h2>

        <div className="section-bg-light dark:section-bg-dark p-4 space-y-4;">
          <TopQuestions data={project?.top_questions} loading={false} />
        </div>
      </section>

      <section>
        <h2>Inventory and Spend Estimates</h2>
        <div className="section-bg-light dark:section-bg-dark p-4 space-y-4">
        {/* { project?.search_inventory_summary
          ? <div className="ai-summary"><Markdown>{ project.search_inventory_summary }</Markdown></div>
          : <p>Inventory summary is not available.</p>
        } */}

        <ForecastCharts>
          <EstimatedSpendType data={ project?.keyword_universe } />
          <EstimatedSpendCategory data={ project?.keyword_universe } category_type="Condition" />
          <EstimatedSpendCategory data={ project?.keyword_universe } category_type="Branded" />
        </ForecastCharts>

        <UniverseTable data={ project?.keyword_universe } />
        </div>
      </section>
    </div>
  );
};

export default KeywordUniverse;
